import { useRef } from "react";
import React from "react";
import { useAnimation, motion } from 'framer-motion/dist/framer-motion'

const textVariantsGold = {
    visible: { opacity: 1, scale: 1, x: 0, y: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, x: 0, y: 25, scale: 1 }
  };

  type PageProps = {
    className?: string
    children?: JSX.Element | JSX.Element[];
  };

  
 export default  React.memo( function Appear (props: PageProps)   {
    let controls = useAnimation();
    
    const ref = useRef(null)
   
    

    return (
     
            <motion.div
                ref={ref}
                whileInView="visible"
                viewport={{ once: true }}
                    animate={controls}
                    variants={textVariantsGold}
                    initial="hidden"
                    className={props.className ? props.className : ''}
                
                >
                        {props.children}
       
            </motion.div>
        
      
      
    );
    
  }
 )