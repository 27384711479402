import { useRef } from "react";
import React from "react";
import { useAnimation, motion } from 'framer-motion/dist/framer-motion'

const textVariantsGiggle = {
    visible: { opacity: 1, scale: 1, x: 0, y: 0, transition: { type:'spring',stiffness: 500,daming:4 } },
    hidden: { opacity: 0.8, x: 0, y: 0, scale: 0.8 }
  };

  type PageProps = {
    className?: string
    children?: JSX.Element | JSX.Element[];
  };

  
 export default   React.memo( function Giggle (props: PageProps)   {
    let controls = useAnimation();
    
    const ref = useRef(null)
   
    

    return (
     
            <motion.div
                ref={ref}
                whileInView="visible"
                viewport={{ once: true }}
                    animate={controls}
                    variants={textVariantsGiggle}
                    initial="hidden"
                    className={props.className ? props.className : ''}
                
                >
                        {props.children}
       
            </motion.div>
        
      
      
    );
    
  }
 )