// Types
type IConfig = {
    decimals: number;
    airdrop: Record<string, number>;
};

// Config from generator
const whitelistConfigClaim: IConfig = {
  "decimals": 0,
  "airdrop": {
    // "0x34A8830Ad29D2Cdf60e1D3905de12aCc3cde41cD": 30,
    // "0x522Fa5024bd27F3D5B19dc5634B1A174F459f513": 3,
    "0x76e6123Dbf7d0137dB9ccA79A4B5F3151d782DE6": 2,
    // "0xA6E4cd837970b82bd6B77b2E53c9A41c169EA7Ac": 3,
    // "0x8B59cc9a10f49073B673fC335095C51afEdecaa4": 3,
    // "0x544B02dDc16d2537B05eA6ce0a2314FB9ceC6233": 3,
    // "0xA98459dBFAd70890A65f475c41d49467f5325309": 3,
    "0x39E121297240Ac7F72E7487D799a0fC06422e216": 2,
    "0x2503802147c0fD850A090996D9Caa77E813491Ab": 2,
    "0x5aeB0EE4b2a68848444176bD3E522810A224e35A": 2,
    "0x7ec87A5719979103E7d22D2Cd8d56ed0d0f305D0": 2,
    "0xBc3340130876Edb51AC044D8658b4260Eb4a1C29": 2,
    "0x4e643f0E1B64d1c238eFb031877D10bfBFb9e07E": 2,
    "0xef21390B0152BBd4Ff50B5b2026369F1E861ed7B": 3,
    "0x5692ef8e26c82d5dD19A740d568bb2DeD33F5226": 6,
    "0xE139bF5f7D306B95ce398a834aF56540a7D9c08D": 3,
    "0x8D26557e19098577232f88af402cB9a6dF1e217A": 6,
    "0xdb5f816A3Ae4b8da93cb7a2A623d7894D5641f55": 9,
    "0x1cC7389d76Af67d326cF631B84b50b174AEe9583": 6,
    "0xF2272b538a5eb3F9639dbECAdE3C2154190c8A26": 6,
    "0x7644a712bAaaE12cA637C392B792C6Ea689E18fc": 6,
    "0x01335CE0Ce30A7018bC90f362861F0C858De565C": 6,
    "0xC15f387A438092DF7525F21Be2D091F12EF9C527": 6,
    "0xb81cD063d19A31Ed790D9927820fe88d0eD540f0": 3,
    "0x152BF16d63cCb09B952578ad094949fF3b9E1614": 6,
    "0x2F8a471a79807702152538899b83C85483995aF9": 6,
    "0xC0c96f22e0a22dAec7D0491f40DA677B695b5919": 6,
    "0xF68454B50d421061fEA85DB8f41f2ed6832EB824": 6,
    "0x61981a7b727D0F5d158AF97721Afb5bcfDC6e592": 6,
    "0xCC1f97b9A04F2d7fD3f4D20Ca4643404a008A37b": 3,
    "0x111DEf62760C99419114FC1d3B30A059163ef923": 3,
    "0xb6E484C97D54f160C011D9cDde7423C84Eb16D05": 3,
    "0x0500D0c70c7F965CcD23DF198C46FC7cD79ad57c": 3,
    "0x78D39777843129f3A73752916a7D2622319519bf": 3,
    "0x000B5E83dD32ba4BAb4b3D1744a73b36CAaac715": 6,
    "0xe2Baa789eAeC1F04952F9840d27E69AB9c846ee8": 3,
    "0x67aa976601Cf3E825570aB2Dd2cD905a6ffACa22": 6,
    "0x8dC4F007B48ce600e8085Eeb42B7C5bb52c084fC": 6,
    "0xCCFec0814DEBD989FFa3eDaA2BeA2264CE0f0194": 3,
    "0x1b9D47946CE9f91e53feFa8FA17Fa53666faccd4": 3,
    "0x7a2ab5B2b382Ebb1F079ec7A8ADD8b4f09c82275": 6,
    "0xF4124F1E3c76eB6C77Db3864F0a79Dc223179765": 3,
    "0x0Ea5Bbb0B22Ccb357f82042e32C6Cd5b4509c9B2": 6,
    "0x3b9ff2030a398C0775F2679957BA7e6e8e4Df328": 6,
    "0xAcfD751991ce3A45408aa4AB28DD7113dad66420": 3,
    "0x8F6FB64fFFd32fF9D764EF3064f613A97048A490": 3,
    "0x47a88e435c8440D039e01efC3b8E697815Cf1028": 6,
    "0x5b525D861B7cB762DCd1A41E962363F56CcBE8Ae": 3,
    "0x6650aaE4483447224FcE806252a192Ba592e2962": 3,
    "0x74aeDA4D163B66Cab524b0FC591e217B547Ff479": 3,
    "0x8064634E983f28589E384d107dE2384a94Fb3555": 3,
    "0x6505cDA5F6df058F6c1997B031011b8ec47D781B": 3,
    "0x48B417277feaEBd8d28dE927696b3dc0665ab88F": 3,
    "0xF7756E161d7f9cBeB5fb5c83a968a4162fF98C3D": 3,
    "0x0d5a88E5cA2EC0D6c1AcB94b9071907B2c4B6058": 3,
    "0x12adDA8628f23F15916668294dc35374B5B901a8": 6,
    "0x49680C7Cd75eEc4DB0d2ac6a8Ece3A0064Fc1647": 6,
    "0x0D419eec34B79c13C8E8cdE4Fd887A7d3CCE8d4e": 6,
    "0x9534c49d55d35de976FC34dD8347b8893f2b6929": 3,
    "0xb082dC4b54830EC9AFce182DB02F8726ec0EBD4D": 3,
    "0xF0eeD267b656903239AE7d23B54bf5350cbc17cF": 30,
    "0xC61607B5c61b1AB215348e87442D0E2B3D54A6c7": 12,
    "0x4BCb26A28c09Fb68be2B5124d9AdA3710a111e25": 6,
    "0xA01D7E4e848467CBD2CA864150f27A9D286C86C8": 6,
    "0x3f381342F04BF796F61156a7312781b41AF24b7e": 3,
    "0x471a7CD14cEd68c5bA6411a1f3d5CA1419168c9A": 3,
    "0xaA0abdbc0Af54A09003FC0c12a8aAf05C120DC07": 6,
    "0x03d6eDd0b7538c2d2B00fe424C6BCcC9C6DF2A0D": 3,
    "0xE964cc444513AE0D5Ce82871c59Bb7b859498972": 6,
    "0xBCCd5E9740529BfA377314Bac0B3651289CdC613": 6,
    "0x81c3De12024cE5E2D0BC062C10c410cce7bc1Ad1": 6,
    "0xa39fC89ECB9132930e3aEc8ECcbeFbe312b125ff": 3,
    "0xDB9A429770fc75c2efC173B0A9db8e4B6e73e6BD": 6,
    "0x0d2f55A1e3216a67155F18AE41E0CE52d0b253D9": 9,
    "0xa08d64c331d0662A6B2D9DeDD4aaf41e98a5E785": 3,
    "0xb11E72e820F7b51Bbc99fB705ba4DD5f95E46086": 3,
    "0x1Cd25F6b7E567998e85729A00A5D0BFC155E2910": 3,
    "0xF454e42f07e1A63793a32075DF92F3aCA7B094a2": 3,
    "0x464248Efc32c138a29D6E35d3AE1D52Aa7472c49": 3,
    "0x90AcBeF726a6154599EDC87bC9538e6756dE1d16": 3,
    "0x9938493d23148b0Ae3d222484DB334EEf840cCC8": 3,
    "0xDf97EC1F9E895871eC7b668Da3480deB4663795e": 3,
    "0x6485E3f7644c4622e9b57dd0e79839705217603b": 3,
    "0x5538a75ebe2BABBCB65e47009161E6c4aEFF0A29": 3,
    "0x2a719Ad873Ce3e8e921aDC1EEEBB6DD0f9F380ab": 3,
    "0xc7abD14D6B5E4e4Af0bac4363da19f75da2E5d03": 3,
    "0x41270847ae2b55b2dD9efD4c6AedE58a3a003CA1": 3,
    "0xA91E1431c92D066EBCA8a85A6533D9Ad13EBd6D1": 3,
    "0x93536325Db2F8938d1b60447A9a6680106f828f1": 3,
    "0xF6d33436209801BCEEb91896E6461ff25B058b9b": 27,
    "0x42C5FaAC05ed6A3d3D4390F56e1553D7Da2eA16D": 12,
    "0xDffb0C8579DB5A49255D92bA53fA27b6afAEcC84": 15,
    "0x16B1a3A66B0Dff9F14CBe38f5c64E1106515a591": 3,
    "0x5628f3946cd272B0f3c20E11dfCa3772F50EB201": 3,
    "0x2fb90f9CB2ed5d50300997d20553bD6E2F9C969F": 3,
    "0x1420Dd16F7D541536C7B1cBC5e712E2b1bAFf12F": 3,
    "0xFAFBB149f0A7E2c768fE6151a1Fc0d69BC7B57EE": 3,
    "0x74Fa0e881958af8cf7282b7f3C3dA3ae1cA00A98": 3,
    "0x7809Cf5128C181985A7F28540Bf5c7470453D55c": 3,
    "0xBf8Fa1a9C5EF0F1b2AdeF9Ff9986c51999d2c0e0": 3,
    "0x6918ef656CF8916d8c9917913e7483992AB2e1b3": 3,
    "0x7900C4b8eD0BBC863b084dAAB57e636A286b81e8": 3,
    "0xFC7BF6d30c090bc3d28f2925ee6f97450A2BE58f": 3,
    "0x247F7332E775DA7F31C17D4769d0Bf58257D4C6f": 3,
    "0xC8342a07F7c5e7a9d7167D45086dF2276e02462F": 6,
    "0x762e430d9Ef958b4Bcbb47C8B3F7657dc1DdfeD0": 3,
    "0xC94505ccfB4B8c40D400A337f2576Cb5d27A7f58": 3,
    "0x2064b45554b07CF8dCeB597fEC3361e47D9e312f": 3,
    "0x9A24Fff7eEC63219684c317F4F3811bb407b96Cd": 3,
    "0x3B3b1493F138988f27866551082c135571A1dFDE": 3,
    "0x4dDb2fb923d45a1d4C4338C481cd152b03612462": 6,
    "0xAF4A0651E043D4f7519AF06654501A574359B0A9": 27,
    "0x598ecf668f174582f4aD4b577B07d80be569f1c8": 6,
    "0x14268087d71C98711d3007b876236933b5f99910": 6,
    "0x623F7bd1A86a0fa46D519B17286519137Da658de": 9,
    "0x6A6742ac8CBC89031d1A0dAC4e19c293C982E558": 3,
    "0x7faadA5A64b782b5d4b2bB02F16c282950c46AEF": 3,
    "0xE70791993a1B64762DA2e9325059313a2028E456": 6,
    "0x9330e2eC152B4007Dda71d24907e231f3f628923": 3,
    "0xd7e83835ac2d5883798A001C6072681cfe248B81": 3,
    "0xEfF99009107Fbec21B3d7A4Df67B8259A44A34B2": 3,
    "0x1B67830661995F844A03ba764afC497D9661E3eD": 3,
    "0x13560B61D3Caa948521466223c09d55bf887b080": 3,
    "0x2e4A7935122308ffB35983117C248f50BcBa9CC6": 3,
    "0xa5091c00689834cbeAa653E933639051aCf953fa": 6,
    "0x83616eFCD5d6A2606CC23C5a8ecd8383908Bc5c2": 3,
    "0xF6882ddDD762eAAEDb470c5160123E44ccAC7571": 3,
    "0xf99C8FfbAEA1DaB443ccAF4d89c339d9Ae1e9ef8": 21,
    "0xC5a47D6f60C7cb223dA9A75E9D2eC4AF7976c462": 12,
    "0x91f1cF41987F92fFeCF714953c2f04a890521CF6": 1,
    "0xC17ae647Ad99940021a394197b3d02a4EF458C1E": 1,
    "0xD5c7614987A4D8E5eabFc6d3b39FC774E48e253b": 2,
    "0x0526CB2FA20BcABe3ee8B64C573ADc6A1a2F7564": 2,
    "0x982494Ee285472F29e3A3aBB4924d383c75b3bbA": 3,
    "0x3be81c1c74302e0eEC6D7faE95F606FcccaC1B44": 3,
    "0xBc16478ACfF513e21403b207B6517CD12464e4BB": 5,
    "0x3F4D5fFA4CEC6083f377318D0Ca8bdA14D5e61B8": 5,
    "0x1D7A920352EBAe1c5dB23b95922D6eA4aB26f41B": 30,
    "0x48538CbB0bEBa49C411E523cB938C24dbf7d40CD": 20,
    "0xc829F267a31B01f391979E155a55fbFa84A7d2c1": 10
  }
}

;


// Export config
export default whitelistConfigClaim;