// https://github.com/bschulte/nes-react/blob/master/example/src/App.js
import React from 'react';
import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { BigNumber, ethers, providers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider'
import CoinbaseWalletSDK from "@coinbase/wallet-sdk"
// import WalletLink from 'walletlink'
import Web3 from 'web3';
import Web3Modal from "web3modal"; 

import NFTContractABI from './CryptoDudes.json';
import NFTWhiteRussianABI from './DudesWhiteRussian.json';
import NFTMutantsABI from './DudesMutants.json';

// import logo from './logo.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

// import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";
import {Route, Switch, BrowserRouter} from 'react-router-dom'

import { useAnimation, motion } from 'framer-motion/dist/framer-motion'

import {createRef} from 'react' 
import './App.css';

import whitelistConfig from './whitelistConfig';
import whitelistConfigClaim from './whitelistConfigClaim';

import keccak256 from "keccak256"; // Keccak256 hashing
import MerkleTree from "merkletreejs"; // MerkleTree.js

import Countdown from 'react-countdown';

import Giggle from './Giggle';
import Appear from './Appear';
import {
  Container,
  Button,
  Avatar,
  Balloon,
  Icon,
  Sprite,
  ControllerIcon,
  List
} from "nes-react";

import Row from "./Row";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { cpuUsage } from 'process';
import { parse } from '@ethersproject/transactions';

const { verifyMessage } = require('@ambire/signature-validator')

// import { cpuUsage } from 'process';
// import Col from "./Col";

// interface PageProps {
//   children: JSX.Element | JSX.Element[];
//   className?: string;
// }


function parseDate(date) {
  const parsed = Date.parse(date);
  if (!isNaN(parsed)) {
    return parsed;
  }

  var offset = new Date().getTimezoneOffset();
  // console.log('offset: ',offset);

  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' ')) - ( offset * 60 * 1000);
}

// ************  things to change !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// totalSupplyExpected = ?
// networkAwaited = ?
// showmint true/false = ?
// whitelist periode duration = ?
// exact date in code to display countdowns
// whitelistconfig à mettre à jour avec le dernier update !!!!!!!!
// //pour test revealuri: QmV26XEoCSpwSkGFP9pAuAG1Bh6Ax1vnpnn5YrNUSrQDAy
// https://testnets.opensea.io/collection/crytodudes-tkkcwtp9uj   avec 0x5C48b898655290B6b03A190CeA6FE7244C2fFde7
// reveal testnet c.setRevealedURI('ipfs://QmV26XEoCSpwSkGFP9pAuAG1Bh6Ax1vnpnn5YrNUSrQDAy/')
// ipfs://bafybeialqtdhxrek4lgdtamnuqhro2baccdjzb3es6icvpymabqywjoxv4/  real revealed uri final !!!!!!!!!!
// c.releaseAll() (get money)
// c.setStep(4)  (4 = frozen)
// real metadata QmXgLoK3Dd7pp2Aw65288WEVoAamQuztcgV5ULY3y3PDxq


const mintStart = parseDate("2022-07-06 18:40:00 UTC");
//const claimStart = parseDate("2022-07-04 16:30:00 UTC");
const claimStart = parseDate("2022-07-06 16:30:00 UTC");

const mutantClaimStart = parseDate("2022-10-31 16:00:00 UTC");
const mutantWLStart = parseDate("2022-10-31 18:00:00 UTC");
const mutantPublicStart =  parseDate("2022-10-31 20:00:00 UTC");


const totalSupplyExpected = '2222';
const networkAwaited = 'main'; // rinkeby or main or goerli
// const NFTContractAddress = '0x5C48b898655290B6b03A190CeA6FE7244C2fFde7'; // rinkeby
//const NFTContractAddress = '0x508c5463194B300801d28fcCd49aaE35F6331C47'; // main official

let NFTContractAddress;
let NFTWhiteRussianAddress;
let NFTGenesisAddress;
let NFTMutantAddress;
let INFURA_ID;

if (networkAwaited == 'main') {
  NFTGenesisAddress = '0x508c5463194b300801d28fccd49aae35f6331c47';
  NFTContractAddress = '0xf62519cc5c275f790713898e90ca54c78160d6ED';     // main 
  NFTWhiteRussianAddress = '0x3293d8b2425ECAd69D582b475c93f3b2FbaBf544'; // main
  NFTMutantAddress = '0xB68b18F5B87d5fDB4111b966A6fb2EfcAEb6e7A6';
  INFURA_ID = 'b73410caedba4331871895baf9da2612' ;
} else {
  NFTGenesisAddress = '0x58FB43f91c53d549F809cBb43f9217111f01E22A';
  NFTContractAddress = '0x58FB43f91c53d549F809cBb43f9217111f01E22A'; // goerli
  NFTWhiteRussianAddress = '0x5dbE32ba6A006d2C8d155752907492557b85Dac2'; // goerli
  NFTMutantAddress = '0x7076Cb375fEAa5f4Bfad8D767e5fc58f85008308'; //goerli
  INFURA_ID = '297456297f7c4efea678fa18bd03f52b';
}

// INFURA_ID = '297456297f7c4efea678fa18bd03f52b' // pour les test localhost

const providerOptions = {
  /* See Provider Options Section */
  metamask: {
    id: "injected",
    name: "MetaMask",
    type: "injected",
    check: "isMetaMask"
    },
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID, // Required
        // network: "rinkeby",
        // qrcodeModalOptions: {
        //     mobileLinks: [
        //     "rainbow",
        //     "metamask",
        //     "argent",
        //     "trust",
        //     "imtoken",
        //     "pillar"
        //     ],
            
        // }
      }
      
  
  },
  walletlink: {
    package: CoinbaseWalletSDK, 
    options: {
      appName: "CryptoDudes",
      infuraId: INFURA_ID 
    }
  }
  

  // 'custom-walletlink': {
  //   display: {
  //     logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
  //     name: 'Coinbase',
  //     description: 'Connect to Coinbase Wallet (not Coinbase App)',
  //   },
  //   options: {
  //     appName: 'Coinbase', // Your app name
  //     networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
  //     chainId: 1,
  //   },
  //   package: WalletLink,
  //   connector: async (_, options) => {
  //     const { appName, networkUrl, chainId } = options
  //     const walletLink = new WalletLink({
  //       appName,
  //     })
  //     const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
  //     await provider.enable()
  //     return provider
  //   },
  // },
};

const Featured = [
  // {
  //   image: "/featured/small/SolSea_Logo light.svg",
  //   link: "https://solsea.io/collection/61ffdf6fcdf4ac4451e49f1c"
  // },
  // {
  //   image: "/featured/small/raritysniper.png",
  //   link: "https://raritysniper.com/metaeggs-city"
  // },
  {
    image: "/featured/small/nftcalendar2.png",
    link: "https://nftcalendar.io/event/cryptodudes-free-mint/"
  },
  {
    image: "/featured/small/nftevening.png",
    link: "https://nftevening.com/event/crypto-dudes-free-mint/"
  },
  {
    image: "/featured/small/rugdoc-main-logo.svg",
    link: "https://rugdoc.io/project/cryptodudes/"
  },
  {
    image: "/featured/small/nextdrop.png",
    link: "https://nextdrop.is/project/11812"
  },
  // {
  //   image: "/featured/small/notfin.png",
  //   link: "https://notfin.com/metaeggs-city/"
  // },
  // {
  //   image: "/featured/small/2nft.png",
  //   link: "https://2nft.org/metaeggs-city/"
  // },
  
]


const web3Modal = new Web3Modal({
  // network: "mainnet", 
  cacheProvider: true,
  providerOptions,
  theme: {
    background: "rgb(39, 49, 56)",
    main: "rgb(199, 199, 199)",
    secondary: "rgb(136, 136, 136)",
    border: "rgba(195, 195, 195, 0.14)",
    hover: "rgb(16, 26, 32)"
  }
  // required
});

// const web3Modal = new Web3Modal({
//   network: "ropsten", // optional ropsten
//   cacheProvider: false, // optional
 
// disableInjectedProvider: false,
//   providerOptions // required
// });

  let provider = undefined;
  let library = undefined;
  // let signer = undefined;
  let web3 = undefined;
  let ethersProvider = undefined;
  let contract = undefined;

function App() {
 
  const showMint = false;
  const showComingSoon = false;
  const showConnect = true;
  const showMutantMint = true;

  // 28800 minutes in contract * 60 sec * 1000 ms,  value in seconds * 1000 here !!!
  const WHITELISTPERIOD = 43200 * 1000; 
  const [mintAmount,setMinAmount] = useState(1)
  const [isSoldOut, setIsSoldOut] = useState(false)
  const [askRender, setAskRender] = useState(true)

  const [error, setError] = useState('');
  const [data, setData] = useState({'WLcost': '0', 'cost': '0', 'totalSupply': '0', 'startTime': 0})
  const [dataMutants, setDataMutant] = useState({'WLcost': '0', 'cost': '0', 'totalSupply': '0', 'startTime': 0})
  

  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState('');

  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isWhitelistedClaim, setIsWhitelistedClaim] = useState(false);
  const [claimableAmount, setClaimableAmount] = useState(0)

  const [isMinting, setIsMinting] = useState(false);

  const [allowedMutantClaim, setAllowedMutantClaim] = useState(0);
  const [ownedDudes, setOwnedDudes] = useState([]);
  const [ownedDudesGenesis, setOwnedDudesGenesis] = useState([]);
  const [ownedWhiteRussian, setOwnedWhiteRussian] = useState([]);
  const [ownedMutant, setOwnedMutant] = useState([]);

  const [isRevealed, setIsRevealed] = useState(false);
  // const [sellingStep, setSellingStep] = useState(0);
 

  var date = new Date();
  var offsetms = date.getTimezoneOffset() * 60 * 1000;


  function generateLeaf(address, value) {
    return Buffer.from(
        // Hash in appropriate Merkle format
        ethers.utils
            .solidityKeccak256(["address", "uint256"], [address, value])
            .slice(2),
        "hex"
    );
}

function mintAmountPlus(maxAllowed)
{
  if (mintAmount < maxAllowed){
      setMinAmount(mintAmount+1)
  }
}

function mintAmountMinus()
{
  if (mintAmount > 1){
      setMinAmount(mintAmount-1)
  }
}

// Setup merkle tree claim
const merkleTreeClaim = new MerkleTree(
  // Generate leafs
  Object.entries(whitelistConfigClaim.airdrop).map(([address, tokens]) =>
      generateLeaf(
          ethers.utils.getAddress(address),
          ethers.utils.parseUnits(tokens.toString(), whitelistConfigClaim.decimals).toString()
      )
  ),
  // Hashing function
  keccak256,
  { sortPairs: true }
);

// Setup merkle tree
const merkleTree = new MerkleTree(
    // Generate leafs
    Object.entries(whitelistConfig.airdrop).map(([address, tokens]) =>
        generateLeaf(
            ethers.utils.getAddress(address),
            ethers.utils.parseUnits(tokens.toString(), whitelistConfig.decimals).toString()
        )
    ),
    // Hashing function
    keccak256,
    { sortPairs: true }
);

const getAirdropAmountClaim = (address) => {
  // If address is in airdrop
  if (address in whitelistConfigClaim.airdrop) {
      // Return number of tokens available
      return whitelistConfigClaim.airdrop[address];
  }

  // Else, return 0 tokens
  return 0;
};

const getAirdropAmount = (address) => {
  // If address is in airdrop
  //console.log(address.toString().toLowerCase() )
  if ( address.toString().toLowerCase() in whitelistConfig.airdrop) {
      // Return number of tokens available
      //console.log('ici')
      return whitelistConfig.airdrop[address.toString().toLocaleLowerCase()];
  }

  // Else, return 0 tokens
  return 0;
};



  //const providerOptions = {};
  
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>-</span>;
    } else {
      // Render a countdown
      return <span className="text-white">{days}d {hours}h {minutes}m {seconds}s</span>;
    }
  };

  const Team = [
    {
      "image": "/img/kobi.webp",
      "name": "Olivier Huard aka 'Kobi'",
      "title": "World-renowned pixel artist all around the world.",
      "subtitle": "Artist",
      "description": "Author of [pixelart.fr] , exhibited at the Grand Palais in Paris, published in “The Master of Pixel Art”. Check out his amazing work - 25 years XP! That rug really tied the room together.",
      "twitter": " https://twitter.com/huard_olivier",
      "linkedin": "",
      "instagram": "https://www.instagram.com/pixelartmafia/",
      "web": "https://huardolivier.myportfolio.com/"
  },
    {
        "image": "/img/john100.png",
        "name": "Jonjohn.eth",
        "title" : "Head of marketing",
        "subtitle": "CMO",
        "description": "It’s good knowin’ he’s out there. The Dude. Takin’ her easy for all us sinners.",
        "twitter": " https://twitter.com/Jonjohn_eth",
        "linkedin": "",
        "instagram": "",
        "web" : ""
    },
    {
      "image": "/img/steph100.png",
      "name": "0xlez.eth",
      "title": "Web 3.0 engineer",
      "subtitle": "CTO",
      "description": "Addicted to Apple products and apps. F*ck it, Dude. Let’s go bowling.",
      "twitter": "https://twitter.com/0xlez",
      "linkedin": "",
      "instagram": "",
      "web" : ""
    },
    {
      "image": "/img/jul100.png",
      "name": "Dude Master",
      "title": "Operating officer and designer",
      "subtitle": "COO",
      "description": "Formerly a pro poker player and webmaster in the entertainment industry. I’m the Dude, so that’s what you call me.",
      "twitter": "https://twitter.com/Cryptodude_0001",
      "linkedin": "",
      "instagram": "",
      "web" : ""
    },

  ]
  const FAQ = [

    {
      "question": "Hey Dude, wen mint ?",
      "answer": "Sold out, but waiting for the new mutant collection on the 31th of october !",
      "imgLeft": "/img/john100.png",
      "imgRight": "/img/dudeLeft.png",
      "scale": -1
    },
    {
      "question": "Cool, can I use it as my avatar ?",
      "answer": "Yeah, that's what it's meant for.",
      "imgLeft": "/img/steph100.png",
      "imgRight": "/img/dudeLeft.png", 
      "scale": 1
    },
    {
      "question": "Great, can't wait!",
      "answer": "I can’t be worrying about that sh*t. Life goes on, man.",
      "imgLeft": "/img/jul100.png",
      "imgRight": "/img/dudeLeft.png",
      "scale": 1
    },

  ]

  useEffect(() => {
    (async () => {
       if(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) await connectPrompt();
    })()
  }, [])

  async function connectPrompt() {
    // console.log('connectPrompt')
    
    

    try {
      //console.log('ee')
     
      provider = await web3Modal.connect();
      //console.log('kkkk')
    }
    catch(err)
    {
      // console.log(err)
    }
    // console.log('haha')
    if (provider !== undefined) {
      // console.log('instance')

      web3 = new Web3(provider);
      //  console.log(web3)
      //  const firstAccount = await web3.eth.getAccounts().then(data=>data[0]);
      //  setAccount(firstAccount);
      ethersProvider = new providers.Web3Provider(provider)
      // signer = ethersProvider.getSigner();
      
      // contract = new ethers.Contract(NFTContractAddress, NFTContractABI, ethersProvider );

      provider.on('error', e => console.error('WS Error', e));
      provider.on('end', e => console.error('WS End', e));

      provider.on('disconnect', (error) => {
        console.log(error);
        setAccount('')
        // contract = undefined;
      });
      provider.on('connect', (info) => {
        console.log(info);
      });
      provider.on('accountsChanged', (accounts) => {
        console.log('account ch', accounts);
        //setAccount(accounts[0]);
        setAccount('')
        fetchAccountData()
        setMinAmount(1)
      });
      provider.on('chainChanged', (chainId) => {
        console.log('chain ch', chainId);
        //setAccount(accounts[0]);
        setAccount('')
        fetchAccountData()
      });
      provider.on('networkChanged', (networkdId) => {
        console.log('chain ch', networkdId);
        //setAccount(accounts[0]);
        setAccount('')
        fetchAccountData()
      });

      await refreshAccountData()
    }
  }
  
  async function disconnect() {
    await web3Modal.clearCachedProvider();
    setAccount('')
  }
  
  
  useEffect( () => {
   
  },[isMinting, isWhitelisted, isWhitelistedClaim, mintAmount, isSoldOut, ownedDudes, ownedDudesGenesis, ownedWhiteRussian, ownedMutant])


  useEffect( () => {
    let claim = 0;

    if (ownedDudes.length > 0 && ownedWhiteRussian.length > 0) {
    //if (ownedDudes.length > 0) {
       claim = Math.min(ownedDudes.length, ownedWhiteRussian.length);
     // claim = ownedDudes.length;
      claim = claim + 3 - ownedMutant.length;
    } else {
      claim = 3;
      claim = claim - ownedMutant.length;
    }
    if (claim < 0) {
      claim = 0;
    }
    console.log('owned dudes ',ownedDudes.length)
    console.log('owned WR ',ownedWhiteRussian.length)
    console.log('owned mutant', ownedMutant.length)
    console.log ('max claim ',claim)
    setAllowedMutantClaim(claim)
    

  },[ ownedDudes, ownedWhiteRussian, ownedMutant])


  useEffect(() => {
    
    if (!!provider) {

      const library = new ethers.providers.Web3Provider(provider)
      // console.log('rr')
        const updateBlockNumber2 = async () => {
            // console.log('ttffff ')
         

            if (provider && library && account != '') {

                await fetchData(account)

                library.once('block', updateBlockNumber2)
            }

        }
        library.once('block', updateBlockNumber2)

        return () => {
            //stale = true
            library.removeListener('block', updateBlockNumber2);
            //setBlockNumber(undefined)
        }
    }
}, [provider])

  useEffect( () => {
   
    //  loadWeb3();
      fetchData(account);
    //  getAccounts();

    // if(window.ethereum) {
    //   window.ethereum.on('chainChanged', () => {
    //     window.location.reload();
    //   })
    //   window.ethereum.on('accountsChanged', () => {
    //     window.location.reload();
    //   })
    // }
     // console.log('use effect')``

    if (account !== '') 
    {
      const allowedAmount = getAirdropAmount(account);
      const allowedAmountClaim = getAirdropAmountClaim(account);

      if (allowedAmount === 1){
        setIsWhitelisted(true)
      } else{
        setIsWhitelisted(false)
      }
     // console.log(allowedAmountClaim)
      if (allowedAmountClaim >= 1){
        setIsWhitelistedClaim(true)
        setClaimableAmount(allowedAmountClaim)
      } else{
        setIsWhitelistedClaim(false)
        setClaimableAmount(0)
      }

    }
    else
    {
      setIsWhitelisted(false)
      setIsWhitelistedClaim(false)
      setClaimableAmount(0)
    }

  },[account])
  
  async function refreshAccountData() {
    await fetchAccountData(provider)
  } 

  async function fetchAccountData() {

    // console.log('fetch')
     const accounts = await web3.eth.getAccounts();
     let curAccount = accounts[0];
     setAccount(curAccount)
     setOwnedDudes([])
     setOwnedDudesGenesis([])
     setOwnedWhiteRussian([])
     setOwnedMutant([])

    //console.log(accounts[0])
     const balance = await web3.eth.getBalance(accounts[0])
     const ethBalance = web3.utils.fromWei(balance, "ether")
     const humanBalance = parseFloat(ethBalance).toFixed(4)

     //console.log('account balance', humanBalance)

     const networkType = await web3.eth.net.getNetworkType()

     const toastIdNetwork = "wrongNetwork";
      // console.log(networkType)
     if (networkType !== networkAwaited)
     {
        toast.error('You are connected to the wrong network! Please connect on ETH ' + networkAwaited, { toastId: toastIdNetwork})
     }

     fetchData(curAccount);
    

  }
  
  async function askRefresh() {
    fetchData(account)
  }

  // function refreshPage() {
  //   window.location.reload(false);
  // }


  async function fetchData(curAccount) {
    // og('fetch data')
    // console.log('>',curAccount)
   // const accounts = await web3.eth.getAccounts();

    if (curAccount !== '' && curAccount !== undefined) {
      
      const contract = new ethers.Contract(NFTContractAddress, NFTContractABI, ethersProvider );
      const contractGenesis = new ethers.Contract(NFTGenesisAddress, NFTContractABI, ethersProvider );
      
      const whiteRussianContract = new ethers.Contract(NFTWhiteRussianAddress, NFTWhiteRussianABI, ethersProvider );
      const mutantContract = new ethers.Contract(NFTMutantAddress, NFTMutantsABI,ethersProvider);

      // console.log(contract)
      //console.log(NFTContractABI)
      try {
       //  console.log("<<",curAccount, contract)
          
        const WLcost = await contract.wlSalePrice()
        const cost = await contract.publicSalePrice();
       
        const startTime = await contract.saleStartTime();
        
        const totalSupply = await contract.totalSupply();
        const totalSupplyMutants = await mutantContract.totalSupply();
      
        const myNFTs = await contract.tokensOfOwner(curAccount);
        const myNFTsGenesis = await contractGenesis.tokensOfOwner(curAccount);
        const myWhiteRussian = await whiteRussianContract.tokensOfOwner(curAccount);
        const myMutant = await mutantContract.tokensOfOwner(curAccount);
     
        const sellingStep = await contract.sellingStep();
     
       // console.log('start time ', startTime.toString())
       // console.log(">>",curAccount)
       //  console.log(">>",myNFTs)
        if (sellingStep > 0) {
          setIsRevealed(true);
        }
        // setSellingStep(sellingStep);

        //console.log('step:', sellingStep)
        // startTime = Date.now() +  (60*1000);
        // console.log('time',startTime)
        // const totalSupply = await contract.totalSupply();
        // const object = {'WLcost' : String(WLcost) , 'cost': String(cost) , 'totalSupply': String(totalSupply)};
        const object = {'WLcost' : String(WLcost) , 'cost': String(cost), 'totalSupply' : String(totalSupply), 'startTime': Number((startTime.toString()))*1000  }; //, 'totalSupply': String(totalSupply)};
        setData(object)

        const objectMutant = {'WLcost' : String(WLcost) , 'cost': String(cost), 'totalSupply' : String(totalSupplyMutants), 'startTime': Number((startTime.toString()))*1000  }; //, 'totalSupply': String(totalSupply)};
        setDataMutant(objectMutant)

         //console.log('set data ok')
         //console.log(object)

        //console.log(Date.now())
        //console.log(startTime)
        if (object.startTime > 0 )
        {
          // WHITE LIST PERIOD, refresh at the end
          if (Date.now() >=  object.startTime &&  Date.now()  <= (object.startTime + WHITELISTPERIOD) )
          {
              //console.log('refresh at the end of presale in ms: ',  (object.startTime + WHITELISTPERIOD - (Date.now() ) ) )
              setTimeout(askRefresh, 1000 + (object.startTime + WHITELISTPERIOD - (Date.now() ) )   )
            
          } 
          // BEFORE, refresh at start
          else if (Date.now() < object.startTime)
          {
              //console.log('refresh at mint start in ms ', (object.startTime - (Date.now() ) )  )
               setTimeout(askRefresh, 1000 + (object.startTime - (Date.now() ) )  )
          }
          else {
            // console.log('mint time')
            // console.log(Date.now())
            // console.log(object.startTime)
          }
        }

        if (object.totalSupply === totalSupplyExpected  || true ) {
          setIsSoldOut(true)
        }

        if (myNFTs.length > 0){
          setOwnedDudes(myNFTs);
          // for (let i=0; i< myNFTs.length; i++){
          //   console.log('owned nft id: ',myNFTs[i].toString());
          // }
        }
        else
        {
          setOwnedDudes([])
        }

        if (myNFTsGenesis.length > 0){
          setOwnedDudesGenesis(myNFTsGenesis);
        }
        else
        {
          setOwnedDudesGenesis([])
        }

        if (myWhiteRussian.length > 0) {
          setOwnedWhiteRussian(myWhiteRussian);
        }
        else{
          setOwnedWhiteRussian([]);
        }

        if (myMutant.length > 0) {
          setOwnedMutant(myMutant);
        }
        else{
          setOwnedMutant([]);
        }

      }
      catch (err){
        setError(err.message);
       // toast.error(err.error.message)
        // console.log(err.message)
      }

      
    }
    
  }

  // async function getAccounts() {
  //   if (typeof window.ethereum !== 'undefined') {
  //     let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
  //     setAccount(accounts);
  //     console.log(accounts[0]);
  //   }
  // }

  async function whitelistMintClaim(wlQuantity) {
   
    setIsMinting(true)
    const allowedAmount = getAirdropAmountClaim(account);
  
    
    // console.log(allowedAmount)
    // console.log(account)
    // console.log(data)
  
    if (account !== '' && allowedAmount >= 1 ) {
      // console.log('white list min')

      

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(NFTContractAddress, NFTContractABI, signer );
      try {
  
        const numTokens = ethers.utils
        .parseUnits(whitelistConfigClaim.airdrop[account].toString(), whitelistConfigClaim.decimals)
        .toString();
  
        // Generate hashed leaf from address
        const leaf = generateLeaf(account, numTokens);
        // Generate airdrop proof
        const proof = merkleTreeClaim.getHexProof(leaf);

        //console.log(account, numTokens, leaf, proof)
  
        let overrides = {
          from: account,
          value: '0'
        }
        const transaction = await contract.ClaimMint(account, wlQuantity, proof, overrides);
        await transaction.wait()
        toast.success('Congratulations! Transaction successful!')
        fetchData(account)
      }
      catch(err){
        // setError(err.error.message)
        if (err.error !== undefined) {
         toast.error(err.error.message)
        }
        else {
          toast.error(err.message)
        }

      }
    }
    setIsMinting(false)
    askRefresh()
  }


  async function whitelistMint(wlQuantity) {
   
    setIsMinting(true)
    const allowedAmount = getAirdropAmount(account);
  
    
    // console.log(allowedAmount)
    // console.log(account)
    // console.log(data)
  
    if (account !== '' && allowedAmount >= 1 ) {
      // console.log('white list min')
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(NFTContractAddress, NFTContractABI, signer );
      try {
  
        const numTokens = ethers.utils
        .parseUnits(whitelistConfig.airdrop[account].toString(), whitelistConfig.decimals)
        .toString();
  
        // Generate hashed leaf from address
        const leaf = generateLeaf(account, numTokens);
        // Generate airdrop proof
        const proof = merkleTree.getHexProof(leaf);

        //console.log(account, numTokens, leaf, proof)
  
        let overrides = {
          from: account,
          value: BigNumber.from(data.WLcost).mul(wlQuantity).toString()
        }
        const transaction = await contract.whitelistMint(account, wlQuantity, proof, overrides);
        await transaction.wait()
        toast.success('Congratulations! Transaction successful!')
        fetchData(account)
      }
      catch(err){
        // setError(err.error.message)
        if (err.error !== undefined) {
         toast.error(err.error.message)
        }
        else {
          toast.error(err.message)
        }

      }
    }
    setIsMinting(false)
    askRefresh()
  }
  

  async function mutantClaimMint() {
    
    setIsMinting(true)
    // if (typeof window.ethereum !== 'undefined') {
    //   let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
    //   const provider = new ethers.providers.Web3Provider(window.ethereum);
    //   const signer = provider.getSigner();
    // console.log("mint ",quantity)
    if (account !== '' ) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(NFTMutantAddress, NFTMutantsABI, signer );

      try {

        let overrides = {
          from: account,
          value: BigNumber.from(data.cost).mul(mintAmount).toString()
        }
        const transaction = await contract.DudesWRMint(account, ownedDudes, NFTContractAddress, ownedWhiteRussian, NFTWhiteRussianAddress, overrides);
        await transaction.wait()
        toast.success('Congratulations! Transaction successful!')
        fetchData(account)
      }
      catch(err){
        setError(err.message)
        console.log(err)
        if (err.error !== undefined) {
          toast.error(err.error.message)
         }
         else {
           toast.error(err.message)
         }
      
        //console.log(err.message)
      }
    }
    setIsMinting(false)
    askRefresh()
  }

  async function mutantWLMint (wlQuantity) {
   
    setIsMinting(true)
    const allowedAmount = getAirdropAmount(account);
  
    
    // console.log(allowedAmount)
    // console.log(account)
    // console.log(data)
  
    if (account !== '' && allowedAmount >= 1 ) {
      // console.log('white list min')
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(NFTMutantAddress, NFTMutantsABI, signer );
      try {
  
        
        // Generate hashed leaf from address
        const leaf = generateLeaf(account.toString().toLocaleLowerCase(), 1);
        // const leaf = generateLeaf(account, numTokens);
        // Generate airdrop proof
        const proof = merkleTree.getHexProof(leaf);

        //console.log(account, numTokens, leaf, proof)
  
        let overrides = {
          from: account,
          value: BigNumber.from(data.WLcost).mul(wlQuantity).toString()
        }
        const transaction = await contract.whitelistMint(account, wlQuantity, proof, overrides);
        await transaction.wait()
        toast.success('Congratulations! Transaction successful!')
        fetchData(account)
      }
      catch(err){
        // setError(err.error.message)
        if (err.error !== undefined) {
         toast.error(err.error.message)
        }
        else {
          toast.error(err.message)
        }

      }
    }
    setIsMinting(false)
    askRefresh()
  }

  async function mutantPublicMint (wlQuantity) {
    const proofAccount = '0x34a8830ad29d2cdf60e1d3905de12acc3cde41cd';
    setIsMinting(true)
    const allowedAmount = getAirdropAmount(proofAccount);
  
    
    // console.log(allowedAmount)
    // console.log(account)
    // console.log(data)
  
    if (account !== '' && allowedAmount >= 1 ) {
      // console.log('white list min')
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(NFTMutantAddress, NFTMutantsABI, signer );
      try {
  
        
        // Generate hashed leaf from address
        const leaf = generateLeaf(proofAccount, 1);
        // const leaf = generateLeaf(account, numTokens);
        // Generate airdrop proof
        const proof = merkleTree.getHexProof(leaf);

        //console.log(account, numTokens, leaf, proof)
  
        let overrides = {
          from: account,
          value: BigNumber.from(data.WLcost).mul(wlQuantity).toString()
        }
        const transaction = await contract.publicSaleMint(account, wlQuantity, proof, proofAccount, overrides);
        await transaction.wait()
        toast.success('Congratulations! Transaction successful!')
        fetchData(account)
      }
      catch(err){
        // setError(err.error.message)
        if (err.error !== undefined) {
         toast.error(err.error.message)
        }
        else {
          toast.error(err.message)
        }

      }
    }
    setIsMinting(false)
    askRefresh()
  }


  async function publicMint() {
    
    setIsMinting(true)
    // if (typeof window.ethereum !== 'undefined') {
    //   let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
    //   const provider = new ethers.providers.Web3Provider(window.ethereum);
    //   const signer = provider.getSigner();
    // console.log("mint ",quantity)
    if (account !== '' ) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(NFTContractAddress, NFTContractABI, signer );

      try {

        let overrides = {
          from: account,
          value: BigNumber.from(data.cost).mul(mintAmount).toString()
        }
        const transaction = await contract.publicSaleMint(account, mintAmount, overrides);
        await transaction.wait()
        toast.success('Congratulations! Transaction successful!')
        fetchData(account)
      }
      catch(err){
        setError(err.message)
        console.log(err)
        if (err.error !== undefined) {
          toast.error(err.error.message)
         }
         else {
           toast.error(err.message)
         }
      
        //console.log(err.message)
      }
    }
    setIsMinting(false)
    askRefresh()
  }


  async function whiteRussianMint() {
   
    setIsMinting(true)
    
    // console.log(allowedAmount)
    // console.log(account)
    // console.log(data)
  
    if (account !== '' && ownedDudes.length >= 1 ) {
       //console.log('white list min')
      //console.log(window.ethereum);
      // const test =  await window.ethereum.request({ method: 'eth_requestAccounts' })
      // console.log(test)

      const providerB = await web3Modal.connect();
      const provider = new providers.Web3Provider(providerB)
      const signer = provider.getSigner(account);

     
      
      //const provider = new ethers.providers.Web3Provider(window.ethereum);

     // const provider = new ethers.providers.Web3Provider(window.ethereum);
      
     // console.log(signer)
      
      const contract = new ethers.Contract(NFTWhiteRussianAddress, NFTWhiteRussianABI, signer );
      try {
  
        let overrides = {
          from: account,
          value: 0
        }
        //console.log('ici ', account)
        const transaction = await contract.publicMint(account, ownedDudes, NFTContractAddress, overrides);
        //console.log('la ', account)
        await transaction.wait()
        toast.success('Congratulations! Transaction successful!')
        fetchData(account)
      }
      catch(err){
        // setError(err.error.message)
        if (err.error !== undefined) {
         toast.error(err.error.message)
        }
        else {
          toast.error(err.message)
        }

      }
    }
    setIsMinting(false)
    askRefresh()
  }

  async function whiteRussianMintGenesis() {
   
    setIsMinting(true)
    
    // console.log(allowedAmount)
    // console.log(account)
    // console.log(data)
  
    if (account !== '' && ownedDudesGenesis.length >= 1 ) {
      // console.log('white list min')
      const providerB = await web3Modal.connect();
      const provider = new providers.Web3Provider(providerB)

      //const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
      const contract = new ethers.Contract(NFTWhiteRussianAddress, NFTWhiteRussianABI, signer );
      try {
  
        let overrides = {
          from: account,
          value: 0
        }

        const transaction = await contract.publicMint(account, ownedDudesGenesis, NFTGenesisAddress, overrides);
        await transaction.wait()
        toast.success('Congratulations! Transaction successful!')
        fetchData(account)
      }
      catch(err){
        // setError(err.error.message)
        if (err.error !== undefined) {
         toast.error(err.error.message)
        }
        else {
          toast.error(err.message)
        }

      }
    }
    setIsMinting(false)
    askRefresh()
  }




  // async function testButton() {
    
  //   setIsMinting(true)
   
  //   await setTimeout(500)
  //   setIsMinting(false)
  // }

  // async function withdraw() {
  //   if (account !== '') {
  //     // let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
  //     // const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     // const signer = provider.getSigner();
  //     // const contract = new ethers.Contract(NFTContractAddress, NFTContractABI.abi, signer );
  //     try {

  //       const transaction = await contract.releaseAll();
  //       await transaction.wait()
    
  //     }
  //     catch(err){
  //       setError(err.message)
  //     }
  //   }
  // }
  
  const [isOpen, setIsOpen] = useState(false);
  
//console.log('dee', Date.now())
  //console.log(Date.parse("2022-05-30 16:40:49 UTC"))
//console.log(isSoldOut)
//console.log(data.startTime)
  return (
    <div className="flex justify-center ">
    <div className="max-w-6xl">
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='dark'
      />

    <BrowserRouter basename=''>
   

		<nav className="bg-black  text-sm text-white">
			<div className=" mx-auto px-2">
				<div className="flex justify-between text-white">
					<div className="flex space-x-7 ">
						<div>
							{/* <!-- Website Logo --> */}
							<div className="flex items-center py-4  hover:no-underline block lg:hidden">
                {/* <img src="/img/logo_medium.png" /> */}
								{/* <Button style={{backgroundColor: '#bed4f6', boxShadow:'inset -4px -4px #86a6d7', position:'absolute'}}>&nbsp;&nbsp;&nbsp;&nbsp;CRYPTODUDES</Button>
                <Avatar src="/img/home-fixe.png" large className='' style={{position: 'relative', bottom: '12px', left: '4px', zIndex: '1000'}}/> */}
                
              
                <a href="/">
                 <Avatar src="/img/dude.png" large className='' style={{position: 'relative', bottom: '12px', left: '4px', zIndex: '1000'}}/> 
                 </a>
                 <div>
                
                 <a href="/" className="text-white hover:text-pink-500 hover:no-underline"><h1 className="xs:text-xl sm:text-2xl pl-0 py-0 my-0 pt-1">CRYPTODUDES</h1></a>
                 <a href="/" className="text-pink-500 hover:text-green-500 hover:no-underline"><p className="pl-2 text-xs text-extrabold  py-0">Just take it easy, man.</p></a>
                 </div>
               
                 
                 
							</div>
              
						</div>
						{/* <!-- Primary Navbar items --> */}
						<div className="hidden lg:flex items-center space-x-1">
							
              
						</div>
					</div>
					{/* <!-- Secondary Navbar items --> */}
					<div className="hidden lg:flex items-center space-x-3 text-xs">
          {/* <AnchorLink href='#about' className="py-4 px-2 text-gray-800 font-semibold hover:text-pink-500 hover:no-underline transition duration-300">About</AnchorLink> */}
          <AnchorLink href='#artist' className="py-4 px-2 text-white font-semibold hover:text-pink-500 hover:no-underline transition duration-300">Artist</AnchorLink>
          <AnchorLink href='#roadmap' className="py-4 px-2 text-white font-semibold hover:text-pink-500 hover:no-underline transition duration-300">Roadmap</AnchorLink>
          <AnchorLink href='#FAQ' className="py-4 px-2 text-white font-semibold hover:text-pink-500 hover:no-underline transition duration-300">FAQ</AnchorLink>
          {/* <AnchorLink href='#terms' className="py-4 px-2 text-gray-800 font-semibold hover:text-pink-500 hover:no-underline transition duration-300">Terms</AnchorLink> */}

               <a href="https://opensea.io/collection/cryptodudesbrotherhood" target="_blank" rel="noreferrer"> 
              <Button ><span className="text-black">OpenSea</span></Button>
              </a>
              <a href="https://x2y2.io/collection/cryptodudesbrotherhood/items" target="_blank" rel="noreferrer"> 
              <Button >X2Y2</Button>
              </a>
					</div>
					{/* <!-- Mobile menu button --> */}
					<div className="lg:hidden flex items-center">
						<button className="outline-none mobile-menu-button"  onClick={() => setIsOpen(!isOpen)}>
						<svg className=" w-6 h-6 text-gray-500 hover:text-green-500 "
							x-show="!showMenu"
							fill="none"
							
							strokeWidth="4"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path d="M4 6h16M4 12h16M4 18h16"></path>
						</svg>
					</button>
					</div>
				</div>
			</div>
			{/* <!-- mobile menu --> */}
      { isOpen && 
      (

        <div className="lg:hidden mobile-menu">
				<ul className="">
					<li><AnchorLink href="#artist" className="block  text-white text-sm px-2 py-4 hover:bg-green-500 hover:text-white hover:no-underline  transition duration-300">Artist</AnchorLink></li>
					<li><AnchorLink href="#roadmap" className="block text-white text-sm px-2 py-4 hover:bg-green-500 hover:text-white hover:no-underline  transition duration-300">Roadmap</AnchorLink></li>
					<li><AnchorLink href="#FAQ" className="block  text-white text-sm px-2 py-4 hover:bg-green-500 hover:text-white hover:no-underline  transition duration-300">FAQ</AnchorLink></li>
          <li><AnchorLink href="#terms" className="block  text-white text-sm px-2 py-4 hover:bg-green-500 hover:text-white hover:no-underline  transition duration-300">Terms</AnchorLink></li>
          <li><a href="https://opensea.io/collection/cryptodudesbrotherhood" className="block  text-white text-sm px-2 py-4 hover:bg-green-500 hover:text-white hover:no-underline  transition duration-300">OpenSea</a></li>
          <li><a href="https://x2y2.io/collection/cryptodudesbrotherhood/items" className="block  text-white text-sm px-2 py-4 hover:bg-green-500 hover:text-white hover:no-underline  transition duration-300">X2Y2</a></li>
			
      
          
      	</ul>
			</div>
      )
      
      }
			
		
		</nav>

    <div className="px-4 pt-2" >

    <Switch>
    
      <Route path="/" exact>
{/* <ParallaxProvider>
  <ParallaxBanner
            className="banner"
            layers={[
              {
                image: '/img/_1.png',
                translateY: [0, 40],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: '/img/_2.png',
                translateY: [10, 30],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: '/img/_3.png',
                translateY: [20, 20],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: '/img/_4.png',
                translateY: [30, 10],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
              {
                image: '/img/_5.png',
                translateY: [40, 0],

                shouldAlwaysCompleteAnimation: true,
                expanded: false,
              },
            ]}
          />

<div className="absolute inset-0 flex items-center justify-center">
          
       <img src="/img/twitter_famous.png" style={{width:'50%'}} className=""/>
      </div>

       
</ParallaxProvider> */}





{/* **************************************** MAIN *************************************************************** */}      

<Row>
<div className="flex flex-col w-full">
{/* <Container dark rounded> */}
    <div className="relative">
        <img src="/img/Bowling_web_hallo_2.jpeg"></img>
        <div className="absolute inset-0 flex justify-center  z-1 top-1">
         <div className='hidden lg:block'>
           <AnchorLink href="#main">
          <Button >
                 <div >
                
                 <a href="/" className="text-black hover:text-pink-500 hover:no-underline"><h1 className="xs:text-sm sm:text-2xl pl-0 py-0 my-0 pt-1">CRYPTODUDES</h1></a>
                 <a href="/" className="text-pink-500 hover:text-green-500 hover:no-underline"><p className="pl-2 text-xs text-extrabold  py-0">Just take it easy, man.</p></a>
                 
                 
                 
							</div></Button>
              </AnchorLink>
          </div>
        </div>
    </div>
    
  {/* </Container>  */}
  </div>
<div className="flex flex-col sm:flex-col md:flex-row pt-8">


      
      <div className="flex flex-col w-full md:w-2/3 ">
        <div className="">
        
          {/* <div className="w-full mb-4 mt-4">
              <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t" >fr</div>
          </div> */}
        
          {/* <h2 className="pl-2 text-2xl text-extrabold text-black pt-4">WELCOME TO</h2>
          <h2 className="pl-2 text-2xl text-extrabold text-black">CRYPTODUDES</h2> */}
        
        </div>
        <div className="text-center sm:text-center sm:px-8">

          <summary>
        <div className="pt-2" id="main">
          <p className="px-2 text-white text-sm">Pixel Artworks collection of CryptoDudes chilling on the Ethereum blockchain.</p>
        
        
          </div>
          <div className="pt-4">
            <p className="px-2 text-white text-sm">
            The Dude himself doubles as your membership for the Brotherhood Club & Yield earnings.
            </p>
          </div>

          <div className="pt-4">
            <p className="px-2 text-white text-sm">The Crypto Dudes abide.</p>
          </div>
          </summary>

    
          <div className="pt-4">
            <p className="px-2 text-white text-sm">Powered by Olivier Huard aka <a href="#artist" className="text-green-500 hover:text-green-500 hover:no-underline">[“Kobi”]</a>, renowned pixel artist.</p>
          </div>

          
        
       
        <Giggle >
          <div className="flex pl-1 pt-8 justify-center sm:justify-center"  >
          <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
            <div className="px-1">
              <a href="https://twitter.com/cryptodudes_me" target="_blank" rel="noreferrer">  <Avatar src="/img/twitter_pixel.png" large/></a>
            </div>
            </motion.div>
            <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
            <div className="px-1">
              <a href="https://cryptodudes.me/discord" target="_blank" rel="noreferrer">   <Avatar src="/img/discord_pixel.png" large/></a>
            </div>
            </motion.div>
          </div>
        </Giggle>
      

        {account === '' ? 
                (
                
                    // <button onClick={()=>connectPrompt()}>Connect</button> 
                    <div className="pt-8 text-xs">
                      <Button onClick={()=>connectPrompt()}>Connect</Button>
                    </div>
                )
                :
                ( 
                  <>
                  <div className="pt-8 text-xs">
                          <Button onClick={()=>disconnect()}>Disconnect</Button>
                          
                        </div>
                        <p className="pl-2 text-xs text-white">connected ({account &&
                            `${account.slice(0, 6)}...${account.slice(
                              account.length - 4,
                              account.length
                            )}`})
                            </p>
                            
                  </>
                )
}
      
     

      </div>
    </div>
    
    <div className=" flex justify-center pb-6">
    <div className="w-2/3 md:w-4/5 lg:w-full ">
     
        <Container rounded >
          <img className="mx-auto object-contain" src="/img/mutant/mutant.gif" alt='cryptodudes brotherhood'/>
        </Container>
      
    
    </div>
    </div>
    </div>
</Row>


<Appear>
  <div className="flex justify-between pt-4">
    <div className="pr-2">
        <img src="/img/mutant/mutantdude1.png" alt='cz binance'/>
    </div>
    <div className="px-2 hidden md:block">
        <img src="/img/mutant/mutantdude2.png" alt='cool dude'/>
    </div>
    <div className="px-2 ">
        <img src="/img/mutant/mutantdude3.png" alt='smooking dude'/>
    </div>
    <div className="px-2 hidden md:block">
        <img src="/img/mutant/mutantdude4.png" alt='business dude'/>
    </div>
    <div className="pl-2 ">
        <img src="/img/mutant/mutantdude6.png" alt='matric dude'/>
    </div>
  </div>
  </Appear>


<div className="pt-8">



{showConnect && (
          <>
            <div className="pt-4 text-white"></div>
            {/* <Container  rounded title="CryptoDudes allocation">
                <p className="pt-4 text-white">* <span className="text-green-500">22</span> premint for the team and giveaways</p>
                <p className="text-white">* <span className="text-green-500">605</span> for the brotherhood members</p>
                <p className="text-white">* <span className="text-green-500">1595</span> free mint (sold out) </p>
                <p className="pt-4 text-white"><span className="text-blue-500">2222</span> CryptoDudes <span className="text-green-500">[sold out] !</span></p>
           
            </Container> */}
            {/* <Container  rounded title="CryptoDudes NFT Collections">
                <div className="flex flex-col">
                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="pt-4 text-white">* <span className="text-blue-500"></span> CryptoDudes <span className="text-green-500">- sold out !</span></p>
                      <p className="pt-1 text-white"> <a href="https://opensea.io/collection/cryptodudesbrotherhood" target="_blank" rel="noreferrer"> [Buy on OpenSea]</a></p>
                    </div>
                    <div>
                      <img src="/img/c.jpg" width='64' height='64'></img>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="pt-4 text-white">* <span className="text-blue-500"></span> White Russian <span className="text-green-500">[1 CryptoDude = 1 Free White Russian]</span></p>
                      <p className="pt-1 text-white"> <a href="https://opensea.io/fr/collection/whiterussian-by-cryptodudes" target="_blank" rel="noreferrer"> [Buy on OpenSea]</a></p>
                    </div>
                    <div>
                      <img src="/img/others/whiterussian.png" width='64' height='64'></img>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="pt-8 text-white">* <span className="text-blue-500"></span> Mutants <span className="text-green-500"> - Coming soon</span></p>
                      <p className="pt-1 text-red-400"> /// Mint Date: 31th, October \\\</p>
                      <p className="pt-1 text-white">1 CryptoDude + 1 WhiteRussian = 1 Free Mutant</p>
                      <p className="pt-1 text-white">Details on our <a href="https://cryptodudes.me/discord" target="_blank" rel="noreferrer">[Discord]</a></p>
                      
                      
                    </div>
                    <div>
                      <img className="pt-8" src="/img/mutant/mutantdude1.png" width='64' height='64'></img>
                    </div>
                  </div>


                      </div>
            </Container> */}

            <div className="pt-4"></div>

            {showMutantMint && (

<>
<div className="pt-8"></div>
    <Container  rounded title="The Mutant Dudes">
    <p className="pt-1 text-red-400 text-center"> /// Mint Date \\\</p>
    <p className="pt-1 text-red-400 text-center"> /// 31th, October \\\</p>
    {/* <p className="pt-4 text-white">Let your Dudes drink their beverages, man. You need 1 Dude and 1 White Russian NFTs for the next step!</p> */}
          {/* <Appear>
          <div className="flex justify-evenly pt-4">
            <div className="pr-2">
                <img src="/img/others/whiterussian-small.png" alt='white russian'/>
            </div>
           
          </div>
          </Appear> */}
          <div className="pt-4"></div>

          <div>

            <p className='text-center text-white'>
              Total supply: {dataMutants.totalSupply} / 2222
            </p>

            <p className='text-center text-green-500'>
              Sold out!
            </p>
            <p className="pt-1 text-white text-center"> <a href="https://opensea.io/collection/the-mutant-dudes-by-cryptodudes" target="_blank" rel="noreferrer"> [Buy on OpenSea]</a></p>

            </div>
            <div className="pt-4"></div>

            {/* <div>

            <p className="text-blue-500 text-center pt-4">** Chapter 1 **</p>
            <p className="text-blue-500 text-center">Dude, drink your white russian</p>

            { (Date.now() < mutantClaimStart) && (
              <>
                <p className="text-green-500 text-center">Starts in</p>
                <div className="flex justify-center pb-6">
                  <Countdown date={ mutantClaimStart } onComplete={()=>setAskRender(!askRender)} renderer={renderer}></Countdown>
                </div>
            </>
            )}

          { (Date.now() >= mutantClaimStart) && (Date.now() < mutantPublicStart) && (
              <>
                <p className="text-green-500 text-center">Mint started</p>
               
            </>
            )}

          { (Date.now() >= mutantPublicStart)  && (
              <>
                <p className="text-red-500 text-center">Mint ended</p>
               
            </>
            )}


            <div className="text-white pt-6 flex justify-center">
              <p className="text-center">You own {ownedDudes.length} Dudes & {ownedWhiteRussian.length} white russian(s)</p>
            </div>
            { allowedMutantClaim > 0  && (Date.now() >= mutantClaimStart) && (Date.now() < mutantPublicStart) &&
              <>
                  <div className="flex justify-center pt-4">
                        <Button onClick={()=>mutantClaimMint()} disabled={isMinting} success> {isMinting ? 'Minting...' : ' Mint ' + allowedMutantClaim + ' Mutant(s)'}</Button>
                  </div>
                  
              </>
            }

            { allowedMutantClaim > 0  && (Date.now() < mutantClaimStart) && (Date.now() < mutantPublicStart) &&
              <>
                  <div className="flex justify-center pt-4">
                        <Button onClick={()=>mutantClaimMint()} disabled='true' success> {isMinting ? 'Minting...' : ' Mint ' + allowedMutantClaim + ' Mutant(s)'}</Button>
                  </div>
                  
              </>
            }

            { allowedMutantClaim == 0  &&
              <>
                <div>
                  <p className="text-white text-sm text-center pt-2 text-gray-500">Sorry, you need at least 1 CryptoDude & 1 white russian for this chapter</p>
                </div>
              </>
            }

            {isMinting && (
                <img className="mx-auto object-contain" src="/img/mutant/mutant.gif" alt='cryptodudes brotherhood' width="128" />
            )}
             {!isMinting && (
                <img className="mx-auto object-contain" src="/img/c.jpg" alt='cryptodudes brotherhood' width="128" />
            )}
          </div>


          <div className="pt-6">
            <hr/>
            <p className="text-blue-500 text-center pt-6">** Chapter 2 **</p>
            <p className="text-blue-500 text-center">Is that a whitelisted Mutant Dude?</p>
            
            { (Date.now() < mutantWLStart) && (
              <>
                <p className="text-green-500 text-center">Starts in</p>
                <div className="flex justify-center pb-6">
                <Countdown date={ mutantWLStart } onComplete={()=>setAskRender(!askRender)} renderer={renderer}></Countdown>
                </div>
              </>
            )}
            
            { (Date.now() >= mutantWLStart) && (Date.now() < mutantPublicStart) && (
              <>
                <p className="text-green-500 text-center">Mint started</p>
               
            </>
            )}
            { (Date.now() >= mutantPublicStart)  && (
              <>
                <p className="text-red-500 text-center">Mint ended</p>
               
            </>
            )}
            { isWhitelisted && (Date.now() >= mutantWLStart) && (Date.now() < mutantPublicStart) &&
              <>
                  <div>
                        <p className="text-sm text-center pt-4 text-white">Yes, you are whitelisted!</p>
                  </div>
                  <div className="flex justify-center pt-4">
                        <Button onClick={()=>mutantWLMint(1)} disabled={isMinting} success> {isMinting ? 'Minting...' : 'Mint 1 Mutant'}</Button>
                  </div>
                  
              </>
            }

          { isWhitelisted && (Date.now() < mutantWLStart) && (Date.now() < mutantPublicStart) &&
              <>
                  <div>
                        <p className="text-sm text-center pt-4 text-white">Yes, you are whitelisted!</p>
                  </div>
                  <div className="flex justify-center ">
                        <Button onClick={()=>mutantWLMint(1)} disabled='true' success> {isMinting ? 'Minting...' : 'Mint 1 Mutant'}</Button>
                  </div>
                  
              </>
            }       

            { !isWhitelisted   &&
              <>
                <div>
                  <p className=" text-sm text-center pt-2 text-gray-500">Sorry, your wallet is not whitelisted!</p>
                </div>
              </>
            }

            {isMinting && (
                <img className="mx-auto object-contain" src="/img/mutant/mutant.gif" alt='cryptodudes brotherhood' width="128" />
            )}
             {!isMinting && (
                <img className="mx-auto object-contain" src="/img/c.jpg" alt='cryptodudes brotherhood' width="128" />
            )}
          </div> */}


          {/* <div className="pt-6">
            <hr/>
            <p className="text-blue-500 text-center pt-6">** Chapter 4 **</p>
            <p className="text-blue-500 text-center">Free The Mutants</p>
           
            { (Date.now() < mutantPublicStart) && (
      
            <>
              <p className="text-green-500 text-center">Starts in</p>
              <div className="flex justify-center pb-6">
              <Countdown date={ mutantPublicStart } onComplete={()=>setAskRender(!askRender)} renderer={renderer}></Countdown>
              </div>
            </>
            )}

            { (Date.now() >= mutantPublicStart) && (
              <>
                <p className="text-green-500 text-center">Mint started</p>
               
            </>
            )}

            { (Date.now() >= mutantPublicStart && allowedMutantClaim > 0)  &&
              <>

                 
                  <div className="flex justify-center py-4">
                        <Button onClick={()=>mutantPublicMint(allowedMutantClaim)} disabled={isMinting} success> {isMinting ? 'Minting...' : ' Mint ' + allowedMutantClaim + ' Mutants'}</Button>
                  </div> 
                  
              </>
            }

          { (Date.now() >= mutantPublicStart && allowedMutantClaim == 0)  &&
              <>

                  <div className="flex justify-center pt-4">
                    <p className='text-grey'>sorry, too many mutants in your wallet!</p>
                  </div>
              
                  
              </>
            }

            { (Date.now() < mutantPublicStart)   &&
              <>
                <div>
                  <p className="text-sm text-center pt-4 text-gray-500">Public Mint not started yet!</p>
                </div>
                <div className="flex justify-center ">
                        <Button onClick={()=>mutantPublicMint(1)} disabled='true' success> {isMinting ? 'Minting...' : ' Mint 1 Mutant'}</Button>
                  </div>
              </>
            }

            {isMinting && (
                <img className="mx-auto object-contain" src="/img/mutant/mutant.gif" alt='cryptodudes brotherhood' width="128" />
            )}
             {!isMinting && (
                <img className="mx-auto object-contain" src="/img/c.jpg" alt='cryptodudes brotherhood' width="128" />
            )}
          </div> */}




          {/* <div><Countdown date={ mutantWLtart } onComplete={()=>setAskRender(!askRender)} renderer={renderer}></Countdown></div>

          <div><Countdown date={ mutantPublicStart } onComplete={()=>setAskRender(!askRender)} renderer={renderer}></Countdown></div> */}
          
          {/* <p className='text-red-400 text-center pt-6'>Mint Closed</p>

          <div className="flex justify-center pt-4 text-sm">
            <Button onClick={()=>whiteRussianMint()} disabled={isMinting || ownedDudes.length < 1} success> {ownedDudes.length >= 1 ? ( isMinting ? 'Minting...' : 'A White Russian please (' + ownedDudes.length  +")") : 'No drink for you'}</Button>
          </div> */}

          {/* <div className="flex justify-center pt-4 text-sm">
            <Button onClick={()=>whiteRussianMintGenesis()} disabled={isMinting || ownedDudesGenesis.length < 1} success> {ownedDudesGenesis.length >= 1 ? ( isMinting ? 'Minting...' : "... some more for an OG! ("  + ownedDudesGenesis.length +")" ): 'No more!'}</Button>
          </div> */}

     
    </Container>
    </>

)}


<div className="pt-8"></div>

 <Container  rounded title="CryptoDudes NFT Collections">
                <div className="flex flex-col">
                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="pt-4 text-white">* <span className="text-blue-500"></span> CryptoDudes <span className="text-green-500">- sold out !</span></p>
                      <p className="pt-1 text-white"> <a href="https://opensea.io/collection/cryptodudesbrotherhood" target="_blank" rel="noreferrer"> [Buy on OpenSea]</a></p>
                    </div>
                    <div>
                      <img src="/img/c.jpg" width='64' height='64'></img>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="pt-4 text-white">* <span className="text-blue-500"></span> White Russian <span className="text-green-500">[1 CryptoDude = 1 Free White Russian]</span></p>
                      <p className="pt-1 text-white"> <a href="https://opensea.io/fr/collection/whiterussian-by-cryptodudes" target="_blank" rel="noreferrer"> [Buy on OpenSea]</a></p>
                    </div>
                    <div>
                      <img src="/img/others/whiterussian.png" width='64' height='64'></img>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between">
                    <div>
                      <p className="pt-8 text-white">* <span className="text-blue-500"></span> Mutants <span className="text-green-500"> - Coming soon</span></p>
                      <p className="pt-1 text-red-400"> /// Mint Date: 31th, October \\\</p>
                      <p className="pt-1 text-white">1 CryptoDude + 1 WhiteRussian = 1 Free Mutant</p>
                      <p className="pt-1 text-white">Details on our <a href="https://cryptodudes.me/discord" target="_blank" rel="noreferrer">[Discord]</a></p>
                      
                      
                    </div>
                    <div>
                      <img className="pt-8" src="/img/mutant/mutantdude1.png" width='64' height='64'></img>
                    </div>
                  </div>


                      </div>
            </Container> 


            <div className="pt-4"></div>

            {showComingSoon && (

<>
<div className="pt-8"></div>
    <Container dark rounded title="White Russian">
    <p className="pt-4">Let your Dudes drink their beverages, man. You need 1 Dude and 1 White Russian NFTs for the next step!</p>
      <Appear>
          <div className="flex justify-evenly pt-4">
            <div className="pr-2">
                <img src="/img/others/whiterussian-small.png" alt='white russian'/>
            </div>
            {/* <div className="px-2">
                <img src="/img/others/bowlingball-small.png" alt='bowling'/>
            </div> */}
          </div>
          </Appear>
          <div className="pt-4"></div>
          <p className='text-red-400 text-center pt-6'>Mint Closed</p>

          {/* <div className="flex justify-center pt-4 text-sm">
            <Button onClick={()=>whiteRussianMint()} disabled={isMinting || ownedDudes.length < 1} success> {ownedDudes.length >= 1 ? ( isMinting ? 'Minting...' : 'A White Russian please (' + ownedDudes.length  +")") : 'No drink for you'}</Button>
          </div>

          <div className="flex justify-center pt-4 text-sm">
            <Button onClick={()=>whiteRussianMintGenesis()} disabled={isMinting || ownedDudesGenesis.length < 1} success> {ownedDudesGenesis.length >= 1 ? ( isMinting ? 'Minting...' : "... some more for an OG! ("  + ownedDudesGenesis.length +")" ): 'No more!'}</Button>
          </div> */}

     
    </Container>
    </>

)}

             <div className="">
                { showMint && (account === '' || (Date.now() < claimStart))  && (
                 <>
                   
                      <div className='text-center'>
                      
                      {Date.now() < mintStart  ? (
                        <>
                        <Container rounded>
                        <p><span className="text-green-500 pt-4">FREE MINT</span> starts in</p>
                         <Countdown date={ mintStart } onComplete={()=>setAskRender(!askRender)} renderer={renderer}></Countdown>
                         {isWhitelisted ? (
                                              <>
                                              <p className="pt-4 text-center">Congratulations! Your address is <a href="https://cryptodudes.me/discord" className="text-green-500 hover:text-green-500 hover:no-underline">[Whitelisted]</a> for the free mint!
                                              </p>
                                              </>
                                            ) : (
                         <p className="pt-8">Join us on discord now!</p>
                                            )}
                         <Giggle >
                          <div className="flex pt-1 justify-center sm:justify-center"  >
                              <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
                                  <a href="https://cryptodudes.me/discord" target="_blank" rel="noreferrer">   <Avatar src="/img/discord_pixel.png" large/></a>
                              </motion.div>
                            </div>
                        </Giggle>
                        {claimableAmount > 0 && account !== '' && (
                          <>
                            <p className="text-xs text-white pt-4">As a brotherhood member, you will also be able to claim {claimableAmount} CryptoDudes!</p>
                            <p className="text-xs text-white ">Claim will be opened 3 hours before the free mint.</p>
                            </>
                        )}
                        </Container>
                         </>
                      ) :
                      (
                        <p>Mint started, connect your wallet!</p>
                      )}
                     
                      </div>
                     
                    </>
                
                 
                
                )}

                  { account !=='' && showMint &&  (Date.now() >= claimStart) && (
                    
                     <>

                   { claimableAmount > 0 && false && (
                    <Container rounded title="Brotherhood Claim">

                                    <>
                                      <div className="flex justify-start text-sm">
                                          <div style={{ display: "flex" }}>
                                          <Avatar large  src="/img/steph100.png" style={{ alignSelf: "flex-end" , margin: "0.3rem", width: '100px', height:'100px'}}></Avatar>

                                            <Balloon  
                                              // style={{ minWidth: "240px", maxWidth: "240px" }} 
                                              fromLeft  className="balloonQuestion">
                                              As a brotherhood member, you can claim {claimableAmount} CryptoDudes!
                                            </Balloon>
                                          </div>
                                        </div>
                                  


                                        <div className="flex justify-center pt-4 text-sm">
                                        <Button onClick={()=>whitelistMintClaim(claimableAmount)} disabled={isMinting || !isWhitelistedClaim} success> {isWhitelistedClaim ? ( isMinting ? 'Minting...' : 'Claim'  ) : 'Not Whitelisted'}</Button>
                                        </div>

                                        <div className="text-center text-sm">
                                      {/* <p className="pt-2">{ethers.utils.formatEther(data.cost)} ETH</p> */}
                                          <p className="pt-2">cost: 0 ETH</p>
                                      <p className="text-xs">(exlucing gas fee)</p>
                                      </div>
                                      


                                     

                                    </>
                                 

                    </Container>
                   )}
                   <div className="pt-4"></div>

                           <Container rounded title="Mint">
                        <div className="pt-4">
                          <div className='text-xs'>
                            {/* <Button>Mint 1</Button>
                            <Button primary>Mint 2</Button>
                            <Button success>Mint 3</Button>
                            <Button warning>Mint 4</Button>
                            <Button error>Mint 5</Button> */}
                            {/* <Button disabled>Mint coming soon - may 2022</Button>
                             */}

          <div style={{ display: "flex", justifyContent: 'flex-end' }} className="pl-2" >
             
             <Balloon
               // style={{  minWidth: "240px", maxWidth: "220px" }} 
               fromRight
               className="balloonAnswer"
             >
               {data.totalSupply} / {totalSupplyExpected} minted
             
             </Balloon>
             
               <Avatar large  src="/img/thedude_flip.gif" style={{ transform: [{scale: -1}]  , alignSelf: "flex-end" , margin: "0.3rem", width: '100px', height:'100px'}}></Avatar>
          
           </div>


                            {data.startTime > 0  &&
                            (
                              <>
                            
                                  { !isSoldOut && Date.now() < data.startTime && (
                                    <>
                                      
                                       <div className="flex justify-start">
                                          <div style={{ display: "flex" }}>
                                          <Avatar large  src="/img/john100.png" style={{ alignSelf: "flex-end" , margin: "0.3rem", width: '100px', height:'100px'}}></Avatar>

                                            <Balloon  
                                              // style={{ minWidth: "240px", maxWidth: "240px" }} 
                                              fromLeft  className="balloonQuestion">
                                              Whitelist mint not started yet!
                                            </Balloon>
                                          </div>
                                        </div>
                                        <div className="flex flex-col items-center pt-4">
                                            <p className="pt-4">
                                              Whitelist mint starts in <Countdown date={ data.startTime } renderer={renderer}></Countdown>
                                            </p>
                                            <p>
                                               { new Date(data.startTime ).toLocaleDateString() } {new Date(data.startTime ).toLocaleTimeString()}
                                             </p>
                                            {isWhitelisted ? (
                                              <>
                                              <p className="pt-4 text-center">Congratulations! Your address is <a href="https://cryptodudes.me/discord" className="text-green-500 hover:text-green-500 hover:no-underline">[Whitelisted]</a> for the free mint!
                                              </p>
                                              </>
                                            ) : (
                                              <>
                                              <p className="pt-4 text-center">Join our <a href="https://cryptodudes.me/discord" className="text-pink-500 hover:text-green-500 hover:no-underline">[Discord]</a>!
                                            </p>
                                            </>
                                            )}
                                            
                                        </div>

                                    </>
                          
                                  )}

                                          
                                  { !isSoldOut && Date.now() >=  data.startTime &&  Date.now() <= (data.startTime + WHITELISTPERIOD)  && (
                                    <>
                                      <div className="flex justify-start">
                                          <div style={{ display: "flex" }}>
                                          <Avatar large  src="/img/steph100.png" style={{ alignSelf: "flex-end" , margin: "0.3rem", width: '100px', height:'100px'}}></Avatar>

                                            <Balloon  
                                              // style={{ minWidth: "240px", maxWidth: "240px" }} 
                                              fromLeft  className="balloonQuestion">
                                              Whitelist mint opened, hurry up!
                                            </Balloon>
                                          </div>
                                        </div>
                                        <div className="flex flex-col items-center pt-4">
                                        <p className="">
                                              Public mint starts in <Countdown date={ data.startTime +  WHITELISTPERIOD  } renderer={renderer}></Countdown>
                                            </p>
                                            <p>
                                              
                                               { new Date(data.startTime +  WHITELISTPERIOD).toLocaleDateString() } {new Date(data.startTime +  WHITELISTPERIOD).toLocaleTimeString()}
                                             </p>
                                     </div>


                                        <div className="flex justify-center pt-4">
                                        <Button onClick={()=>whitelistMint(2)} disabled={isMinting || !isWhitelisted} success> {isWhitelisted ? ( isMinting ? 'Minting...' : 'Free Mint 2 CryptoDudes'  ) : 'Not Whitelisted'}</Button>
                                        </div>

                                        <div className="text-center">
                                      {/* <p className="pt-2">{ethers.utils.formatEther(data.cost)} ETH</p> */}
                                          <p className="pt-2">cost: 0 ETH</p>
                                      <p className="text-xs">(exlucing gas fee)</p>
                                      </div>
                                      


                                     

                                    </>
                                  )}

                                  { !isSoldOut &&  Date.now()  > ( data.startTime + WHITELISTPERIOD )  && (
                                    <>
                                      <div className="flex justify-start">
                                          <div style={{ display: "flex" }}>
                                          <Avatar large  src="/img/jul100.png" style={{ alignSelf: "flex-end" , margin: "0.3rem", width: '100px', height:'100px'}}></Avatar>

                                            <Balloon  
                                              // style={{ minWidth: "240px", maxWidth: "240px" }} 
                                              fromLeft  className="balloonQuestion">
                                              Public mint opened, hurry up!
                                            </Balloon>
                                          </div>
                                        </div>

                                      <div className="flex justify-center pt-4">
                                        <Button  onClick={()=>mintAmountMinus()} disabled={isMinting || mintAmount===1}> - </Button>
                                        <Button success onClick={()=>publicMint()} disabled={isMinting}> {( isMinting ? 'Minting...' : 'Free Mint' )} {mintAmount} </Button>
                                        <Button  onClick={()=>mintAmountPlus()} disabled={isMinting || mintAmount===10}> + </Button>
                                      </div>
                                      <div className="text-center">
                                      {/* <p className="pt-2">{ethers.utils.formatEther(BigNumber.from(data.cost).mul(mintAmount).toString())} ETH</p> */}
                                      <p className="pt-2">cost: 0 ETH</p>
                                      <p className="text-xs">(exlucing gas fee)</p>
                                      </div>
                                  </>
                                  )}

                                  { isSoldOut && (
                                       <>
                                       <div className="flex justify-center">
                                           <div style={{ display: "flex" }}>
                                           <Avatar large  src="/img/kobi.webp" style={{ alignSelf: "flex-end" , margin: "0.3rem", width: '64', height:'64'}}></Avatar>
 
                                             <Balloon  
                                               // style={{ minWidth: "240px", maxWidth: "240px" }} 
                                               fromLeft  className="balloonQuestion">
                                               Sold out!
                                               {/* <br/>
                                               {totalSupplyExpected - data.totalSupply > 0 && 
                                                <p>{totalSupplyExpected - data.totalSupply } left (gifts)</p> */}
                                               
                                             </Balloon>
                                           </div>
                                         </div>

                                         <div className="flex flex-col items-center pt-4">
                                            
                                              <p className="pt-4 text-center">Stay tuned on our <a href="https://cryptodudes.me/discord" className="text-pink-500 hover:text-green-500 hover:no-underline">[Discord]</a>!</p>
                                          </div>
                                            

                                         </>
                                  )}

                              </>
                            )
                            
                            }

                            {/* <Button error onClick={()=>testButton()} disabled={isMinting}> test </Button> */}


                          </div>
                        </div>
                        </Container>
                      </>
                  )}  
                 


            <div className="pt-8"></div>
            
            {/*   end div are you cool , connect button */}
            </div>
        
       
          </>
        )}

  
</div>

{/* brotherhood -------------------------------------------------------------------- */}



{account !== "" && (ownedDudes.length > 0 || ownedWhiteRussian.length > 0) && (
  <>
  <div className="pt-0" id=""></div>

<Container dark rounded title="CryptoDudes Brotherhood" >
    
<p>
  &nbsp;
 </p>

    <div className="text-xs sm:text-sm text-center" >
     
        <p>Welcome to the brotherhood!</p>
        <p>You own <span className="text-green-500">{ownedDudes.length}</span> CryptoDudes NFTs</p> 
    </div>
    <div className="flex flex-wrap items-center justify-center pt-2">
      {ownedDudes.map((item, index) => (
        <>
          <div className="flex flex-col px-2" key={item.index}>
            {isRevealed ? (
                 <img src={'/img/hidden/a29cbe2a456ef/' + item.toString() + '.png' } width='150px' height="150px"></img>
            ) : (
              
              <img src={'/img/hidden/CryptoDudes_hidden_img/shadow.png'} width='150px' height="150px"></img>
            )
            }
            
            <div>
              <p className="text-center text-xs pt-1">
                CryptoDudes<br/>#{item.toString()}
              </p>
             
            </div>
           
          </div>

        </>
      ))}
    </div>

    {ownedWhiteRussian.length > 0 && (
      <div className="flex flex-wrap items-center justify-center pt-2">
     
          <div className="flex flex-col px-2" >
           
              
              <img src={'/img/others/whiterussian-small.png'} width='150px' height="150px" className='mx-auto'></img>
           
            
            <div>
              <p className="text-center text-xs pt-2">
                <span className="text-green-400">{ownedWhiteRussian.length}</span><br></br>White Russian(s)<br/>
              </p>
             
            </div>
           
          </div>

     
    
    </div>
    )}

{ownedMutant.length > 0 && (
      <div className="flex flex-wrap items-center justify-center pt-2">
     
          <div className="flex flex-col px-2" >
           
              
              <img src={'img/mutant/shadow/MutantDudes_hidden_img/shadow.png'} width='150px' height="150px" className='mx-auto'></img>
           
            
            <div>
              <p className="text-center text-xs pt-2">
                <span className="text-green-400">{ownedMutant.length}</span><br></br>Mutant dudes(s)<br/>
              </p>
             
            </div>
           
          </div>

     
    
    </div>
    )}
    

    
    <p className="pt-6 text-center text-sm">
      Join our <a href="https://cryptodudes.me/discord" className="text-pink-500 hover:text-green-500 hover:no-underline">[Discord]</a> to get access to our private brotherhood!
    </p>
  
    <p>
  &nbsp;
 </p>

</Container>
</>
)}


{/* nft -------------------------------------------------------------------- */}

  <div className="pt-8" ></div>
  <h2 className='text-center pb-4 text-white'>
    Here are a few samples of our cryptodudes
  </h2>
  <Appear>
  <div className="flex justify-between">
    <div className="pr-2">
        <img src="/img/j.jpg" alt='cz binance'/>
    </div>
    <div className="px-2 hidden md:block">
        <img src="/img/a.jpg" alt='cool dude'/>
    </div>
    <div className="px-2 ">
        <img src="/img/b.jpg" alt='smooking dude'/>
    </div>
    <div className="px-2 hidden md:block">
        <img src="/img/h.jpg" alt='business dude'/>
    </div>
    <div className="pl-2 ">
        <img src="/img/e.jpg" alt='matric dude'/>
    </div>
  </div>
  </Appear>
 
 <Appear>
  <div className="flex justify-between pt-4">
    <div className="pr-2">
        <img src="/img/d.jpg" alt='fighter dude'/>
    </div>
    <div className="px-2 hidden md:block">
        <img src="/img/g.jpg" alt='red hat dude'/>
    </div>
    <div className="px-2 ">
        <img src="/img/f.jpg" alt='cyber dude'/>
    </div>
    <div className="px-2 hidden md:block">
        <img src="/img/c.jpg" alt='the dude'/>
    </div>
    <div className="pl-2 ">
        <img src="/img/k.jpg" alt='snoop dogg'/>
    </div>

  </div>
  </Appear>

  <div className="pt-8" id="featured"></div>      
  <Container dark rounded title={<h3>"As seen on"</h3>} >
  <div className=" flex justify-center items-center">
    <div className="flex flex-wrap -mx-px overflow-hidden sm:-mx-px mt-6 justify-center " >

      {Featured.map((item, index) => (
        
            <div key={index} className="my-px px-px w-1/2 overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 ">
              <div className='align-middle px-2' style={{ height: '80px'}}>
              <Appear >
                <motion.div
                
                  whileHover={{ scale: 0.95, rotate: 0 }}
                  whileTap={{
                    scale: 0.95,
                    rotate: 1,
                    borderRadius: "100%"
                  }}
                >
                    <a href={item.link} rel="noreferrer" target="_blank">
                      <img  src={item.image} alt="1" className=" align-middle mx-auto m-2 filter drop-shadow-md p-1" style={{ height: '50px' }} />
                    </a>
                </motion.div>
            
              </Appear>
              </div>
            </div>
          ))}
    </div>
    </div>
    </Container>   



 {/* Kobi the artist -------------------------------------------------------------------- */}
<div className="pt-8" id="artist"></div>

<Container dark rounded title={<h3>"Our artist"</h3>} >
    
<p>
  &nbsp;
 </p>

    <div className="flex text-xs sm:text-sm">
        
      <Avatar src="/img/kobi.webp" large></Avatar>
      <div>             
        <p className="pl-2">Olivier Huard aka “Kobi”, world-renowned pixel all around the world.</p>
        <p className="pl-2">Author of <a href="https://www.pixelart.fr" target="_blank" rel="noreferrer" className="text-pink-500 hover:text-green-500 hover:no-underline">
[pixelart.fr] </a> , exhibited at the Grand Palais in Paris, published in “The Master of Pixel Art”.</p>
        <p className="pl-2">Check out his amazing work - 25 years XP!</p>
      </div>
    </div>
    <p>
  &nbsp;
 </p>

   
    <div className="flex pt-2">
    <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
      <a href="https://huardolivier.myportfolio.com/" target="_blank" rel="noreferrer">
       <Avatar src="/img/web_pixel.png" medium/>
       </a>
       </motion.div>
       <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
       <a href="https://www.instagram.com/pixelartmafia/?hl=fr" target="_blank" rel="noreferrer">
       <Avatar src="/img/instagram_pixel.png" medium/>             
      </a>
      </motion.div>
      <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
      <a href="https://twitter.com/huard_olivier" target="_blank" rel="noreferrer">
       <Avatar src="/img/twitter_pixel.png" medium/>             
      </a>      
      </motion.div>       
      
     
    </div>
    

    {/* <div className="pt-2">
  {[
              "heart",
              "star",
              "twitter",
              "facebook",
              "trophy"
            ].map(icon => (
              <Icon key={icon} icon={icon} />
            ))}
            </div> */}
  
  </Container>


  <div className="py-8 flex justify-center">

      <Giggle>
      <img src="/img/acc/accessories6.png" id="FAQ" width='96px' height='96px' alt='acc6' />
      {/* <Icon icon="heart" id="FAQ"></Icon> */}
      </Giggle>       

</div>

{/* FAQ -------------------------------------------------------------------- */}
<Container rounded title="FAQ" >
          
{FAQ.map((item, index) => (
        
        <div key={index} className="text-xs sm:text-sm">
          
          <Appear>
          <Row>
            <div className="flex">
              <div style={{ display: "flex" }}>
              <Avatar large  src={item.imgLeft} style={{ alignSelf: "flex-end" , margin: "0.3rem", width: '100px', height:'100px'}}></Avatar>

                <Balloon  
                  // style={{ minWidth: "240px", maxWidth: "240px" }} 
                  fromLeft  className="balloonQuestion">
                  {item.question}
                </Balloon>
              </div>
            </div>
           
            </Row>
            </Appear>

            <div className="pt-4"></div>

            <div>
              <Appear>
            <div className="flex justify-end ">
           
              <div style={{ display: "flex", justifyContent: 'flex-end' }} className="pl-2" >
             
                <Balloon
                  // style={{  minWidth: "240px", maxWidth: "220px" }} 
                  fromRight
                  className="balloonAnswer"
                >
                  {item.answer}
                </Balloon>
                
                  <Avatar large  src={item.imgRight} style={{ transform: [{scale: item.scale}]  , alignSelf: "flex-end" , margin: "0.3rem", width: '100px', height:'100px'}}></Avatar>
             
               
                
               

              </div>
              
            </div>
            </Appear>
          </div>

          <div className="pt-20"></div>

          </div>
))}


        </Container>


{/* Kobi the artist -------------------------------------------------------------------- */}
<div className="py-8 flex justify-center">

<Giggle>
  <img src="/img/acc/glasses2.png" id="about" width='96px' height='96px' alt='gla2'/>
  {/* <Sprite sprite="ash" id="about"></Sprite> */}
  </Giggle>

</div>

<Container dark rounded title={<h2>"Who are the Crypto'Dudes ?"</h2>} >
 <div className="text-xs sm:text-sm">
<p>
  &nbsp;
 </p>
  <p>Sometimes there are men…</p>
  <p>
  &nbsp;
 </p>
  <p>
    I won’t say heroes, ’cause what’s a hero? but sometimes there are men, and I’m talking about the Crypto Dudes here, sometimes there are men…
    Well, they are men for their time and place.
    </p>
    <p>
  &nbsp;
 </p>

    <p>
    They fit right in there.</p>
    <p>
  &nbsp;
 </p>

    <p>And that’s the Crypto Dudes on Ethereum.</p>

    <p>
  &nbsp;
 </p>
 </div>

 <img src="/img/acc/glasses6.png"  width='96px' height='96px' alt='gla6'/>

</Container>
{/* founder -------------------------------------------------------------------- */}
<div className="py-8 flex justify-center">
<Giggle>
<img src="/img/acc/glasses5.png"  width='96px' height='96px'  alt='gla5'/>
  </Giggle>

</div>

<Container  rounded title={<h2>"Meet the Founders (Core Team)"</h2>}>
  

  <div className="flex flex-wrap -mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-1">


  {Team.map((item, index) => (
        
       
 <div key={index} className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 xl:my-1 xl:px-1 xl:w-full">
      <Container rounded>
        <Row>
          <div className="flex flex-col sm:flex-col items-center ">
            
            <Avatar large src={item.image} style={{width: "100px", height:'100px'}}></Avatar>
            <div className="pl-2 items-center text-center">
              <h3 className="text-white">
              {item.name}
              </h3>
              <p className="text-green-400"> {item.subtitle}
               </p>
               <p className="text-red-400"> {item.title}
               </p>
               <p className='text-white text-xs sm:text-sm'>
               {item.description}
                </p>
                </div>
             </div>
          </Row>

          <Row>
              <div className="flex pt-4">
                 
                    {item.twitter !== "" && (

                      <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
                            <a href={item.twitter} target='_blank' rel="noreferrer"><Avatar src="/img/twitter_pixel.png" medium/>   </a> 
                      </motion.div>
                     )
                     
                     }
                    {item.linkedin !== "" &&  (
                      <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
                          <a href={item.linkedin} target='_blank' rel="noreferrer"><Avatar src="/img/linkedin_pixel.png" medium/> </a>  
                      </motion.div>
                    )}

                  {item.instagram !== "" &&  (
                      <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
                          <a href={item.instagram} target='_blank' rel="noreferrer"><Avatar src="/img/instagram_pixel.png" medium/> </a>  
                      </motion.div>
                    )}

{item.web !== "" &&  (
                      <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
                          <a href={item.web} target='_blank' rel="noreferrer"><Avatar src="/img/web_pixel.png" medium/> </a>  
                      </motion.div>
                    )}
                             
              
        
              </div>
          </Row>    
      </Container>
      </div>

    ))}

    
  </div>
  

</Container>
 <Container  rounded title="Powered by  Indiana Labs" >
 <div className="flex flex-col sm:flex-col items-center ">
            
            <Avatar large src="/img/indiana.png" style={{width: "100px", height:'100px'}}></Avatar>


            </div>
            <div className="text-xs sm:text-sm text-white">
<p className="pl-2 pt-4">Accelerator for Web3 projects and NFT collections.</p>
<p className="pl-2">All in one solution: Blockchain, Front/Back, Hosting, Smart contracts, and Marketing.</p>
</div>
<div className="flex pt-2">
    <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
      <a href="https://indianalabs.xyz/" target="_blank" rel="noreferrer">
       <Avatar src="/img/web_pixel.png" medium/>
       </a>
       </motion.div>
      
      <motion.div className="social" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}>
      <a href="https://twitter.com/Indianalabs" target="_blank" rel="noreferrer">
       <Avatar src="/img/twitter_pixel.png" medium/>             
      </a>      
      </motion.div>       
      
     
    </div>

</Container> 

{/* roadmap -------------------------------------------------------------------- */}
<div className="py-8 flex justify-center">
<Giggle>
<img src="/img/acc/glasses11.png" id="roadmap" width='96px' height='96px'  alt='gla11'/>
 
  </Giggle>

</div>

<Container dark rounded title={<h2>"Roadmap"</h2>} >
 <div className="text-xs sm:text-sm">
<p>
  &nbsp;
 </p>
 <summary>
  <p>
    We're in this for the long haul. We want to build extreme value for our collectors & investors.  
  </p>
  <p>
  &nbsp;
 </p>
  <p>
    Below are our Roadmap goals & what we have planned for this project. 
  </p>

  <p>
    Welcome to the CryptoDudes' new coolest era.
  </p>
  <p>
  &nbsp;
 </p>
  <p>
    By holding a Crypto Dude you are joining the <span className="text-green-300">private dudes brotherhood.</span>
  </p>
  </summary>
<p>
  &nbsp;
 </p>
  

  <div className="text-center">
  <h3 className="text-pink-500 pt-4 text-lg sm:text-xl">Scene 1</h3>
  <p className="pt-4 text-xs sm:text-sm ">WEB SITE and Smart Contracts</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">Design and Dev work on the site and contracts</p>
  <Giggle>
  <img src="/img/acc/accessories1.png"  width='48px' height='48px' className="m-auto" alt='acc1'/>
  </Giggle>


  <h3 className="text-pink-500 pt-4 text-lg sm:text-xl">Scene 2</h3>
  <p className="pt-4 text-xs sm:text-sm">ART / DUDES COLLECTION</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">Kobi works on the collection</p>
  <Giggle>
  <img src="/img/acc/accessories1.png"  width='48px' height='48px' className="m-auto" alt='acc2'/>
  </Giggle>

  <h3 className="text-pink-500 pt-4 text-lg sm:text-xl">Scene 3</h3>
  <p className="pt-4 text-xs sm:text-sm">COMMUNITY - DISCORD</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">Building the community, marketing</p>
  <Giggle>
  <img src="/img/acc/accessories1.png"  width='48px' height='48px' className="m-auto" alt='acc1'/>
  </Giggle>

  <h3 className="text-pink-500 pt-4 text-lg sm:text-xl">Scene 4</h3>
  <p className="pt-4 text-xs sm:text-sm">MINT</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400 pb-2">The Mint (whitelist first, then public)</p>
  
{/* 
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400"><span className="text-green-500 pt-4 text-xs sm:text-sm">10%</span><br/>We can afford a bowling and chill with our dudes.</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400"><span className="text-green-500 pt-4 text-xs sm:text-sm">20%</span><br/>We release the confined dudes, 5 dudes (token held back from the sale) are airdropped to random CryptoDudes holders.</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400"><span className="text-green-500 pt-4 text-xs sm:text-sm">40%</span><br/>Exclusive community channels, surprise raffles (e.g win a Cryptodudes portrait of yourself) and more...</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400"><span className="text-green-500 pt-4 text-xs sm:text-sm">60%</span><br/>DAO: CryptoDudes owners can vote for the next scenes (NFT owner and/or $DUDE Token)</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400"><span className="text-green-500 pt-4 text-xs sm:text-sm">80%</span><br/>Holders get a private access the CRYPTO DUDES traders club (Crypto trading signals & insights, NFT & ICO early calls) driven by the community.</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400"><span className="text-green-500 pt-4 text-xs sm:text-sm">90%</span><br/>Member-exclusive CryptoDudes merch store gets unlocked, featuring limited-edition tees, hoodies, and other goodies.</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400"><span className="text-green-500 pt-4 text-xs sm:text-sm">100%</span><br/>Holders will get early access to future drops and will be eligible for special perks.</p>
   */}


  <Giggle>
  <img src="/img/acc/accessories1.png"  width='48px' height='48px' className="m-auto" alt=''/>
  </Giggle>

  <h3 className="text-pink-500 pt-4 text-lg sm:text-xl">Scene 5</h3>
  <p className="pt-4 text-xs sm:text-sm ">REVEAL</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">NFT reveal - listing OpenSea</p>
  <Giggle>
  <img src="/img/acc/accessories1.png"  width='48px' height='48px' className="m-auto" alt=''/>
  </Giggle>

  <h3 className="text-pink-500 pt-4 text-lg sm:text-xl">Scene 6</h3>
  <p className="pt-4 text-xs sm:text-sm">TBA</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">The Crypto Dudes holders can vote over decisions that impact the next scene.</p>
  <Giggle>
  <img src="/img/acc/accessories1.png"  width='48px' height='48px' className="m-auto" alt=''/>
  </Giggle>

  <h3 className="text-pink-500 pt-4 text-lg sm:text-xl">Scene 7</h3>
  <p className="pt-4 text-xs sm:text-sm">The White Russian</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">Free Mint, 1 CryptoDude = 1 Free White Russian Glass</p>
  <Giggle>
  <img src="/img/acc/accessories1.png"  width='48px' height='48px' className="m-auto" alt=''/>
  </Giggle>

  <h3 className="text-pink-500 pt-4 text-lg sm:text-xl">Scene 8</h3>
  <p className="pt-4 text-xs sm:text-sm">The Mutants</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">Your CryptoDude will drink a White Russian, who knows what will happen next.</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">Stay tuned, 31th of october</p>
  <Giggle>
  <img src="/img/acc/accessories1.png"  width='48px' height='48px' className="m-auto" alt=''/>
  </Giggle>


  {/* 

  <p className="text-pink-500 pt-4 text-lg sm:text-xl">Phase 6</p>
  <p className="pt-4 text-xs sm:text-sm">TOKEN $DUDE</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">$DUDE ERC20 token launch</p>
  <TextFeatureGiggle>
  <ControllerIcon controller='snes' className="py-8"></ControllerIcon>
  </TextFeatureGiggle>
  <p className="text-pink-500 pt-4 text-lg sm:text-xl">Phase 7</p>
  <p className="pt-2 text-xs sm:text-sm">$DUDE AIRDROP</p>
  <p className="pt-0 pl-0 text-md sm:text-sm text-gray-400">for the CryptoDudes holders</p>
  <TextFeatureGiggle>
  <ControllerIcon controller='nes-jp' className="py-8"></ControllerIcon>
  </TextFeatureGiggle>

  <p className="text-pink-500 pt-4 text-lg sm:text-xl">Phase 8</p>
  <p className="pt-4 text-xs sm:text-sm">2ND EDITION</p>
  <p className="pt-0 pl-4 text-md sm:text-sm text-gray-400">CryptoDudess or Themed collection</p>
  <TextFeatureGiggle>
  <ControllerIcon controller='snes-jp' className="py-8"></ControllerIcon>
  </TextFeatureGiggle>

  <p className="text-pink-500 pt-4 text-lg sm:text-xl">Phase 8 - TBA</p>
  <p className="text-pink-500 pt-4 text-lg sm:text-xl">Phase 8 - TBA</p> */}




</div>
 <p>
  &nbsp;
 </p>
 <img src="/img/acc/accessories2.png"  width='48px' height='48px' alt=''/>
 {/* {["nes", "nes-jp", "snes", "snes-jp"].map(controller => (
            <ControllerIcon
              style={{ margin: 5 }}
              controller={controller}
              key={controller}
            />
          ))} */}

</div>
</Container>





 {/* Specs -------------------------------------------------------------------- */}
 <div className="py-8 flex justify-center" id="artist">
 <Giggle>
 <img src="/img/acc/accessories7.png"  width='96px' height='96px' alt=''/>
   {/* <Avatar large src="/img/invaders.png" /> */}
   </Giggle>
 </div>

<Container  rounded title="Specs - ERC721A" >
    
<p>
  &nbsp;
 </p>

    <div className="flex text-xs sm:text-sm text-white">
        
      {/* <Avatar src="/img/imac.png" ></Avatar> */}
      <div>             
        <p className="pl-2">CryptoDudes is using ERC721A, an improved implementation of the IERC721 standard that supports minting multiple tokens for close to the cost of one. (developed by Azuki)</p>
        <p className="pl-2">Each CryptoDude is unique and programmatically generated from over the possible traits, including expression, headwear, clothing, and more. All Dudes are dope, but some are rarer than others.
        </p>
        <p className="pl-2">
        The CryptoDudes are hosted on IPFS.
        </p>
        <ControllerIcon controller='nes' className="py-8"></ControllerIcon>
        
      </div>
    </div>
    <p>
  &nbsp;
 </p>

   
  
  </Container>


</Route>

<Route path="/terms">
  <>
  <Container  rounded title="Terms & Conditions" >
    
    <p>
      &nbsp;
     </p>
    
        <div className="flex text-xs sm:text-sm text-white">
            
          {/* <Avatar src="/img/imac.png" ></Avatar> */}
          <div>             
            <p className="pl-2">CryptoDudes is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing participants to exchange digital collectibles. Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the CryptoDudes smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.</p>
            <p className="pl-2">This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting sole responsibility for any and all transactions involving CryptoDudes digital collectibles.
            </p>
            
            <ControllerIcon controller='nes' className="py-8"></ControllerIcon>
            
          </div>
        </div>
        <p>
      &nbsp;
     </p>
    
       
      
      </Container>
      <div className="py-8 flex justify-center">

  <Sprite sprite="kirby" id="roadmap"></Sprite>
  

</div>
      <Container  rounded title="CryptoDude Ownership" >
    
    <p>
      &nbsp;
     </p>
    
        <div className="flex text-xs sm:text-sm text-white">
            
          {/* <Avatar src="/img/imac.png" ></Avatar> */}
          <div>             
            <p className="pl-2">1. You Own the NFT. Each CryptoDudes is an NFT on the Ethereum blockchain. 
            When you purchase an NFT, you own the underlying CryptoDudes, the Art, completely. Ownership of the NFT is 
            mediated entirely by the Smart Contract and the Ethereum Network: at no point may we seize, freeze, or otherwise 
            modify the ownership of any CryptoDudes.

            </p>
            <p className="pl-2">
            2. Personal Use. Subject to your continued compliance with these Terms, Indiana Labs grants you a worldwide, 
            royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose 
            to create or use, solely forthe following purposes: (1) for your own personal, non-commercial use; (2) as 
            part of a marketplace that permits the purchase and sale of your CryptoDudes / NFT, provided that the marketplace 
            cryptographically verifies each CryptoDudes owner’s rights to display the Art for their CryptoDudes to ensure that 
            only the actual owner can display the Art; or (3) as part of a third party website or application that permits 
            the inclusion, involvement, or participation of your CryptoDudes, provided that the website/application cryptographically 
            verifies each CryptoDudes owner’s rights to display the Art for their CryptoDudes to ensure that only the actual owner 
            can display the Art, and provided that the Art is no longer visible once the owner ofthe CryptoDudes leaves the website/application.
            </p>

            <p className="pl-2">
            3. Commercial Use. Subject to your continued compliance with these Terms, Indiana Labs grants you an unlimited, 
            worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based 
            upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the Art to produce and 
            sell merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity, nothing in this 
            Section will be deemed to restrict you from (1) owning or operating a marketplace that permits the use and sale of 
            CryptoDudes generally, provided that the marketplace cryptographically verifies each CryptoDudes owner’s rights 
            to display the Art for their CryptoDudes to ensure that only the actual owner can display the Art; (2) owning or 
            operating a third party website or application that permits the inclusion, involvement, or participation of CryptoDudes 
            generally, provided that the third party website or application cryptographically verifies each CryptoDudes owner’s rights 
            to display the Art for their CryptoDudes to ensure that only the actual owner can display the Art, and provided that the Art 
            is no longer visible once the owner of the Purchased CryptoDudes leaves the website/application; or (3) earning revenue 
            from any of the foregoing.
            </p>
            
            <ControllerIcon controller='snes-jp' className="py-8"></ControllerIcon>
            
          </div>
        </div>
        <p>
      &nbsp;
     </p>
    
       
      
      </Container>

</>
</Route>
<Route path="*">
  <Container rounded title="Oooops!">
  <p className='text-center'>Dude, that's a 404!</p>

  <img className="mx-auto" src="/img/rug.png" width="240px"></img>
  </Container>
  </Route>

</Switch>



</div>

</BrowserRouter>





<div className="px-4 pt-8">

<div className="pt-8"></div>

{/* Footer -------------------------------------------------------------------- */}
<div className="py-8 flex justify-center" id="terms">
<Giggle>

  <Icon icon="star"></Icon>
  <Icon icon="star"></Icon>
  <Icon icon="star"></Icon>
  <Icon icon="star"></Icon>
  <Icon icon="star"></Icon>
  </Giggle>

</div>

<Container dark rounded  >
 
  <div className="text-xs sm:text-sm">
  <p>© 2022 Crypto Dudes</p>
  <p>All Rights Reserved | NFT Collection chilling on Ethereum Blockchain
</p>

<a href="/terms" className="text-pink-500 hover:text-green-500 hover:no-underline">
[Terms & Conditions]
</a>

<p className="pt-4">Contact us: <span className="text-green-500">contact@cryptodudes.me</span></p>
</div>


</Container>

<div className="pt-8"></div>

</div>


{/* 
end div margin website */}



    {/* <div className="w-full h-screen bg-center bg-no-repeat bg-cover" >
    <div className="w-full h-screen  flex justify-center items-center">
        <div className="mx-4 text-center text-white">
       
        <div className='flex flex-col items-center'>
                <img src="/heis.png" alt="" width="300px"/>
            </div>

        <div className="flex text-3xl md:text-4xl leading-tight text-center  font-extrabold  justify-center pt-2" >
            <h1 className="text-black">
              CRYPTO
            </h1>
            <h1 className="text-metagreen pl-1">
            DUDES
            </h1>
            </div>

            <h2 className="text-xs md:text-md mb-6">THE COOLEST AVATARS ON SOLANA</h2>
            
            <h4  className="font-bold text-xs mb-12 pt-1 text-black">MINT COMING SOON</h4>
        </div>
        
    </div>
</div> */}





      {/* <div>
          {error && (
            <p>{error}</p>
          )}
          <p>total supply {data.totalSupply}</p>
          <p>each nft cost {data.cost / 10**18} eth </p>
          <button onClick={mint}>mint</button>
          
          {account == "0x1556515" && (
            <button onClick={withdraw}>withdraw</button>
          )}
          
          
      </div>

      <div>
      {account == '' ? <button onClick={()=>connectPrompt()}>Connect</button> : 
         <button onClick={()=>disconnect()}>Disconnect</button>}

      </div> */}





        </div>
        </div>
  );
}

export default App;
